import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { parseJwt, signoutAndRedirect, skipToMainContent } from "common/utils";
import { useDispatch, useSelector } from "react-redux";
import { userSessionTokenApi } from "api/usersApi";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import store from "app/store";
import { ssoLogin } from "pages/Verify/user.slice";
import { HeaderWrapper } from "./styled";
import { showToast } from "../Toast/toast.slice";
import CONSTANTS from "../../common/constants";

declare global {
    interface Window {
        userManager: any;
    }
}
interface Props {
    hideMenu?: boolean;
}
export const Header = (props: Props) => {
    const location = useLocation();
    const userAccessToken = window.localStorage.getItem("userAccessToken");
    const isCorporation =
        userAccessToken && parseJwt(userAccessToken).corporation_id;
    const history = useHistory();
    const dispatch = useDispatch();
    const hospitalId = localStorage.getItem("selectedHospitalId");
    const facilityName = localStorage.getItem("selectedFacilityName");
    const programId = localStorage.getItem("selectedProgramId");
    const categoryId = localStorage.getItem("selectedCategoryId");
    const userState = useSelector((state: any) => {
        return state.user;
    });

    const signout = async () => {
        store.dispatch(setPageLoadingStatus({ isPageLoading: true }));
        await userSessionTokenApi();
        store.dispatch(setPageLoadingStatus({ isPageLoading: false }));
        localStorage.removeItem("isProgramApproved");
        localStorage.removeItem("isHospitalDeActiveOrDeleted");
        store.dispatch(ssoLogin({ user: {} }));
        signoutAndRedirect();
    };

    const checkMaxUsers = () => {
        if (
            userState?.totalFacilitiesRegistered &&
            userState.totalFacilitiesRegistered >= CONSTANTS.HOSPITAL_MAXUSERS
        ) {
            const toast = {
                message:
                    "Maximum limit to add organizations exceeded. Please contact your administrator.",
                code: "Error:",
            };
            dispatch(showToast(toast));
        } else {
            history.push("/organization/register/new-organization");
            window.localStorage.setItem("isProgramApproved", "");
            window.localStorage.setItem("isHospitalDeActiveOrDeleted", "");
        }
    };

    const getNavigationPath = () => {
        if (window.localStorage.getItem("userAccessToken")) {
            const roleCode = parseJwt(
                window.localStorage.getItem("userAccessToken")
            ).role_code;
            switch (roleCode) {
                case CONSTANTS.USER_ROLES.HOSPITAL_ADMIN:
                    return "/userDashboard";
                case CONSTANTS.USER_ROLES.CORPORATION_ADMIN:
                    return "/corporation/dashboard";
                case CONSTANTS.USER_ROLES.REGISTRY_ADMIN:
                    return "/registry/dashboard";
                default:
                    return "#";
            }
        }
        return "#";
    };

    const getAriaLabel = () => {
        let label = "";

        if (
            window.localStorage.getItem("userAccessToken") &&
            parseJwt(window.localStorage.getItem("userAccessToken"))
                .role_code === CONSTANTS.USER_ROLES.HOSPITAL_ADMIN
        ) {
            if (window.location.pathname === CONSTANTS.ROUTES.USER_DASHBOARD) {
                label =
                    "American heart association Quality and Certification tool logo user dashboard page";
            } else {
                label =
                    "American heart association Quality and Certification tool logo return to user dashboard page";
            }
        } else if (
            window.localStorage.getItem("userAccessToken") &&
            parseJwt(window.localStorage.getItem("userAccessToken"))
                .role_code === CONSTANTS.USER_ROLES.CORPORATION_ADMIN
        ) {
            if (
                window.location.pathname ===
                CONSTANTS.ROUTES.CORPORATION_DASHBOARD
            ) {
                label =
                    "American heart association Quality and Certification tool logo dashboard page";
            } else {
                label =
                    "American heart association Quality and Certification tool logo return to dashboard page";
            }
        } else if (
            window.localStorage.getItem("userAccessToken") &&
            parseJwt(window.localStorage.getItem("userAccessToken"))
                .role_code === CONSTANTS.USER_ROLES.REGISTRY_ADMIN
        ) {
            if (
                window.location.pathname === CONSTANTS.ROUTES.REGISTRY_DASHBOARD
            ) {
                label =
                    "American heart association Quality and Certification tool logo dashboard page";
            } else {
                label =
                    "American heart association Quality and Certification tool logo return to dashboard page";
            }
        }

        return label;
    };

    return (
        <>
            <HeaderWrapper className="aui-main-header aui-pri-header">
                <button
                    className="btn btn-link aui-skip-content"
                    onClick={() => skipToMainContent()}
                    role="link"
                >
                    Skip to main content
                </button>
                <div className="navbar navbar-expand-lg justify-content-between aui-header-content mx-auto aui-pri-header-t">
                    <Link
                        to={getNavigationPath()}
                        className="qct-logo"
                        aria-label={getAriaLabel()}
                    >
                        <img
                            src="/images/qct-ems-logo.png"
                            alt="q c t logo for q c t and e m s programs"
                        />
                    </Link>
                    <button
                        className="navbar-toggler ml-2 px-0"
                        type="button"
                        data-toggle="collapse"
                        data-target="#toggleNav"
                        aria-controls="toggleNav"
                        aria-expanded="false"
                        aria-label="main account navigation"
                    >
                        <i className="aha-icon-hamburger" aria-hidden="true" />
                    </button>
                    <div
                        className="justify-content-lg-end collapse navbar-collapse aui-pri-nav"
                        id="toggleNav"
                    >
                        <ul
                            className="navbar-nav ml-lg-3 flex-lg-row flex-column"
                            role="menubar"
                        >
                            {props.hideMenu ? (
                                ""
                            ) : (
                                <li
                                    className="d-flex nav-item dropdown mx-lg-3 flex-column"
                                    role="none"
                                >
                                    <button
                                        type="button"
                                        className="btn btn-text dropdown-toggle flex-grow-1 text-left nav-link"
                                        id="navDropdown1"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        role="menuitem"
                                    >
                                        My Account
                                    </button>
                                    <ul
                                        role="menu"
                                        className="dropdown-menu p-lg-0 aui-header-dropdown"
                                        aria-labelledby="navDropdown1"
                                    >
                                        {window.localStorage.getItem(
                                            "userAccessToken"
                                        ) &&
                                            parseJwt(
                                                window.localStorage.getItem(
                                                    "userAccessToken"
                                                )
                                            ).registry_id > 0 && (
                                                <>
                                                    <li role="none">
                                                        <Link
                                                            to="/registry/user-management"
                                                            className="dropdown-item py-2"
                                                            role="menuitem"
                                                        >
                                                            User Management
                                                        </Link>
                                                    </li>
                                                    <li role="none">
                                                        <Link
                                                            to="/registry/settings/profile"
                                                            className="dropdown-item py-2"
                                                            role="menuitem"
                                                        >
                                                            Settings
                                                        </Link>
                                                    </li>
                                                </>
                                            )}
                                        {window.localStorage.getItem(
                                            "userAccessToken"
                                        ) &&
                                            parseJwt(
                                                window.localStorage.getItem(
                                                    "userAccessToken"
                                                )
                                            ).role_code == "HOSPITAL_ADMIN" && (
                                                <>
                                                    <li role="none">
                                                        <Link
                                                            to="/userDashboard"
                                                            className="dropdown-item py-2"
                                                            role="menuitem"
                                                        >
                                                            My Organizations
                                                        </Link>
                                                    </li>
                                                </>
                                            )}
                                        {window.location.pathname !==
                                            CONSTANTS.ROUTES.USER_DASHBOARD &&
                                            window.localStorage.getItem(
                                                "isProgramApproved"
                                            ) == "true" &&
                                            window.localStorage.getItem(
                                                "isHospitalDeActiveOrDeleted"
                                            ) == "false" &&
                                            window.localStorage.getItem(
                                                "userAccessToken"
                                            ) &&
                                            parseJwt(
                                                window.localStorage.getItem(
                                                    "userAccessToken"
                                                )
                                            ).role_code == "HOSPITAL_ADMIN" && (
                                                <>
                                                    <li role="none">
                                                        <Link
                                                            to={{
                                                                pathname:
                                                                    "/user-management",
                                                                state: {
                                                                    pageNum:
                                                                        location
                                                                            ?.state
                                                                            ?.pageNum,
                                                                    search: location
                                                                        ?.state
                                                                        ?.search,
                                                                },
                                                            }}
                                                            className="dropdown-item py-2"
                                                            role="menuitem"
                                                        >
                                                            Organization User
                                                            Management
                                                        </Link>
                                                    </li>
                                                    <li role="none">
                                                        <Link
                                                            to={{
                                                                pathname:
                                                                    "/settings/organizationdetails",
                                                                state: {
                                                                    pageNum:
                                                                        location
                                                                            ?.state
                                                                            ?.pageNum,
                                                                    search: location
                                                                        ?.state
                                                                        ?.search,
                                                                },
                                                            }}
                                                            className="dropdown-item py-2"
                                                            role="menuitem"
                                                        >
                                                            Organization
                                                            Settings
                                                        </Link>
                                                    </li>
                                                    {programId !==
                                                        CONSTANTS.EMS_PROGRAM_ID && (
                                                        <li role="none">
                                                            <Link
                                                                to={{
                                                                    pathname: `/archived-view/hospital/${hospitalId}/programs/${programId}/categories/${categoryId}`,
                                                                    state: {
                                                                        isArchived:
                                                                            true,
                                                                        facilityName,
                                                                        pageNum:
                                                                            location
                                                                                ?.state
                                                                                ?.pageNum,
                                                                        search: location
                                                                            ?.state
                                                                            ?.search,
                                                                    },
                                                                }}
                                                                className="dropdown-item py-2"
                                                                role="menuitem"
                                                            >
                                                                Archived
                                                            </Link>
                                                        </li>
                                                    )}
                                                </>
                                            )}
                                        {window.location.pathname ===
                                            CONSTANTS.ROUTES.USER_DASHBOARD &&
                                            window.localStorage.getItem(
                                                "userAccessToken"
                                            ) &&
                                            parseJwt(
                                                window.localStorage.getItem(
                                                    "userAccessToken"
                                                )
                                            ).role_code ===
                                                CONSTANTS.USER_ROLES
                                                    .HOSPITAL_ADMIN && (
                                                <li role="none">
                                                    <button
                                                        type="button"
                                                        className="btn btn-text dropdown-item py-2"
                                                        role="menuitem"
                                                        {...(!userState.totalFacilitiesRegistered
                                                            ? {
                                                                  "data-toggle":
                                                                      "modal",
                                                                  "data-target":
                                                                      "#registerModal",
                                                              }
                                                            : {
                                                                  onClick: () =>
                                                                      checkMaxUsers(),
                                                              })}
                                                    >
                                                        Add New Organization
                                                    </button>
                                                </li>
                                            )}
                                        {isCorporation && (
                                            <>
                                                <li role="none">
                                                    <Link
                                                        to="/corporation/user-management"
                                                        className="dropdown-item py-2"
                                                        role="menuitem"
                                                    >
                                                        User Management
                                                    </Link>
                                                </li>
                                                <li role="none">
                                                    <Link
                                                        to="/corporation/settings/profile"
                                                        className="dropdown-item py-2"
                                                        role="menuitem"
                                                    >
                                                        Settings
                                                    </Link>
                                                </li>
                                            </>
                                        )}
                                        <li role="none">
                                            <button
                                                type="button"
                                                onClick={signout}
                                                className="btn btn-text dropdown-item py-2"
                                                role="menuitem"
                                            >
                                                Sign Out
                                            </button>
                                        </li>
                                    </ul>
                                </li>
                            )}
                            <li className="d-flex nav-item px-lg-3" role="none">
                                <a
                                    href="https://www.heart.org/en/volunteer/opportunities"
                                    target="_blank"
                                    className="nav-link"
                                    aria-label="Volunteer Opens in new tab"
                                    rel="noreferrer"
                                    role="menuitem"
                                >
                                    Volunteer
                                </a>
                            </li>
                            <li role="none">
                                <a
                                    href="https://www2.heart.org/site/SPageNavigator/donatenow_heart.html?s_src=20U2W1UEMG&s_subsrc=footer_donatenow"
                                    target="_blank"
                                    className="btn btn-round btn-primary donate-btn ml-lg-2 mt-3 mt-lg-0"
                                    aria-label="Donate Opens in new tab"
                                    rel="noreferrer"
                                    role="menuitem"
                                >
                                    Donate
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </HeaderWrapper>
        </>
    );
};

export default Header;
