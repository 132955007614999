import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import "../../styles/styles.scss";
import { getHospitalsForAdminManage } from "api/adminDashboardAPI";
import CONSTANTS from "common/constants";
import Pagination, { IPagination } from "components/Pagination";
import EMSAgencyTable from "components/EMSAgencyTable";
import TableRowCount from "components/TableRowCount";
import setNegativeTabIndex from "common/dom";
import { getStates, getCountries } from "api/countriesAPI";
import SearchFilter from "components/SearchFilter";
import {
    getAllProgramCategories,
    getPgmMeasuresExportExcel,
    getProgramFieldsById,
} from "api/hospitalProgramsAPI";
import {
    gobalExportFile,
    showModal,
    wordChange,
    localDateToYYYYMMDDFormatString,
    dateToYYYYMMMDDFormat,
    cstDateFormat,
    dateToYYYYMMDDFormatString,
} from "common/utils";
import FocusTrap from "focus-trap-react";
import Dropdown from "components/Dropdown";
import MultiSelectDropdown from "components/MultiSelectDropdown";
import GroupedMultiSelectDropdown from "components/GroupedMultiSelectDropdown";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import webWorkerScript from "common/webWorker";
import { showToast } from "components/Toast/toast.slice";
import {
    updateLockMeasures,
    getMeasureLockData,
    getServiceYear,
    getRegisteredAndAwardedCount,
    bulkRenewFacility,
} from "api/emsAPI";
import store from "app/store";
import Datepicker from "components/Datepicker";
import MoreActions from "components/MoreActions/MoreActions";
import SortTableColumnDropdown from "components/SortTableColumnDropdown";
import { getActiveServiceYear } from "api/bulkRenewApi";
import ServiceYearDropdown from "components/ServiceYearDropdown";
import { getAwardCategories } from "../../../api/certificateAPI";
import {
    setSearchKey,
    setStateCode,
    setAward,
    setMeasure,
    setPageTab,
    setProgramCategoryId,
} from "../../../components/SearchFilter/searchFilter.slice";
import Sidebar from "../Sidebar/Sidebar";
import { OrganizationManagementWrapper } from "./styled";

const paginationSetLimit: number = 5;

export const OrganizationManagement = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    // REDUX variables
    const region = useSelector((state: any) => {
        return state.region;
    });
    const searchFilter = useSelector((state: any) => {
        return state.searchFilter;
    });
    const search = useSelector((state: any) => {
        return state.searchKey;
    });
    const [searchValue, setSearchValue] = useState<any>(
        searchFilter.logsSearchKey
    );
    const [programCategory, setProgramCategory] = useState<any>(
        searchFilter.programCategory
    );
    // RFC STATE variables
    const [tab, setTab] = useState<string>(
        location?.state?.adminOrganizationMangemnetTab || CONSTANTS.PENDING
    );
    const [isActive, setIsActive] = useState<boolean>(tab != "DEACTIVATED");
    const [isPending, setIsPending] = useState<boolean>(false);
    const [pendingHospitals, setPendingHospitals] = useState<any>([]);
    const [country, setCountry] = useState<any>(searchFilter.countryCode);
    const [stateCd, setStateCd] = useState<any>(searchFilter.stateCode);
    const [awardCd, setAwardCd] = useState<any>("ALL");
    const [measureCd, setMeasureCd] = useState<any>("ALL");
    const [registeredHospitals, setRegisteredHospitals] = useState<any>([]);
    const [sortInd, setSortInd] = useState<any>(location?.state?.pageSort);
    const [deactivatedHospitals, setDeactivatedHospitals] = useState<any>([]);
    const [pageNumber, setPageNumber] = useState<number>(
        location?.state?.adminOrganizationMangemnetPage || 1
    );
    const [bulkRenewFailPopUp, setBulkRenewFailPopUp] =
        useState<boolean>(false);
    const paginationSetLimit: number = 5;
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
    const [roleValue, setRoleValue] = useState<string>("none");
    const [isCountryLoading, setIsCountryLoading] = useState<boolean>(false);
    const [isStateLoading, setIsStateLoading] = useState<boolean>(false);
    const [stateDropdown, setStateDropdown] = useState<any>([]);
    const [awardDropdown, setAwardDropdown] = useState<any>([]);
    const [measureDropdown, setMeasureDropdown] = useState<any>([]);
    const [mounted, setMounted] = useState<boolean>(false);
    const [clearFilters, setClearFilters] = useState<boolean>(false);
    const [countryDropdown, setCountryDropdown] = useState<any>([]);
    const [awardCategories, setAwardCategories] = useState<any>([]);
    const exportBtnRestrict = localStorage.getItem("exportBtnRestrict");
    const exportBtnRestrictStatus = exportBtnRestrict === "true";
    const [exportButtonDisabled, setExportButtonDisabled] = useState<boolean>(
        exportBtnRestrictStatus
    );
    const [isSearchResult, setIsSearchResult] = useState<boolean>(false);
    const [lockUnlockActivateInd, setlockUnlockActivateInd] =
        useState<boolean>(false);
    const [measureLockInd, setMeasureLockInd] = useState<boolean>(() => {
        const storedMeasureLockInd = localStorage.getItem("measureLockInd");
        return storedMeasureLockInd ? JSON.parse(storedMeasureLockInd) : false;
    });
    const [measureLockDate, setMeasureLockDate] = useState<any>();
    const [lastYear, setLastYear] = useState("");
    const [presentYear, setPresentYear] = useState<any>("");
    const [modifiedLockDate, setModifiedLockDate] = useState<any>();
    const [minDateForLock, setMinDateForLock] = useState<any>();
    const [isAwardLoading, setIsAwardLoading] = useState<boolean>(false);
    const defaultPagination: IPagination = {
        pageNumber: 1,
        pageSize: 25,
        isFirst: true,
        isLast: false,
        setPageNumber,
        paginationSetLimit,
    };
    const [pendingPagination, setPendingPagination] =
        useState<IPagination>(defaultPagination);
    const [registeredPagination, setRegisteredPagination] =
        useState<IPagination>(defaultPagination);
    const [awardedPagination, setAwardedPagination] =
        useState<IPagination>(defaultPagination);
    const [deactivatedPagination, setDeactivatedPagination] =
        useState<IPagination>(defaultPagination);
    const [lockUpdateButtonState, setLockUpdateButtonState] =
        useState<boolean>(true);
    const [filterInd, setFilterInd] = useState<boolean>(false);

    const [
        exportProgramMeasureActivateInd,
        setExportProgramMeasureActivateInd,
    ] = useState<boolean>(false);
    const [expOrgType, setExpOrgType] = useState<any>([
        CONSTANTS.ORG_TYPE.CERTIFIED,
        CONSTANTS.ORG_TYPE.DEACTIVATED,
        CONSTANTS.ORG_TYPE.REGISTERED,
    ]);
    const [currentServiceYear, setCurrentServiceYear] = useState<any>();
    useEffect(() => {
        getActiveServiceYear().then((response: any) => {
            setCurrentServiceYear(response.data.activeServiceYear.year);
        });
    }, []);
    const getYears = () => {
        const years = [];
        for (let i = currentServiceYear; i >= 2023; i--) {
            years.push(i);
        }
        const yearsDropdownMap: any = [];
        if (years && years.length > 0) {
            years.forEach((year: any) => {
                yearsDropdownMap.push({ label: year, value: year });
            });
        }
        return yearsDropdownMap;
    };
    const yearsDropdown: Array<object> = getYears();
    const [isOrganizationFieldsLoading, setIsOrganizationFieldsLoading] =
        useState<boolean>(false);
    const [isProgramFieldsLoading, setIsProgramFieldsLoading] =
        useState<boolean>(false);
    const [expOrganizationFields, setExpOrganizationFields] = useState<any>([]);
    const [selectedOrganizations, setSelectedOrganizations] = useState<any>([]);
    const [expProgramFields, setExpProgramFields] = useState<any>([]);
    const [selectedPrograms, setSelectedPrograms] = useState<any>([]);
    const [exportButtonState, setExportButtonState] = useState<boolean>(false);
    const [isRequired, setIsRequired] = useState<boolean>(false);
    const [lockUpdateButtonSpinnerState, setLockUpdateButtonSpinnerState] =
        useState<boolean>(false);

    const [activeServiceYear, setActiveServiceYear] = useState<any>();
    const [isActiveServiceYear, setIsActiveServiceYear] =
        useState<boolean>(false);
    const [bulkRenewPopupMsg, setBulkRenewPopupMsg] = useState<any>("");
    const [bulkRenewModalHeader, setBulkRenewModalHeader] = useState<any>("");
    const [facilityRegCount, setFacilityRegCount] = useState<any>(0);
    const [awardedCount, setAwardedCount] = useState<any>(0);
    const [bukRenewModalActiveInd, setBukRenewModalActiveInd] =
        useState<boolean>(false);
    const [isBulkRenewComplete, setIsBulkRenewComplete] =
        useState<boolean>(false);
    const measuresDateFilter = useSelector((state: any) => {
        return state.measuresDateFilter;
    });
    const onChangeExpOrgType = (event: any) => {
        const { value, checked } = event.target;
        checked
            ? setExpOrgType([...expOrgType, value])
            : setExpOrgType(expOrgType.filter((id: any) => id != value));
    };
    const resetModalOnClose = () => {
        setIsOrganizationFieldsLoading(false);
        setSelectedOrganizations([]);
        setSelectedPrograms([]);
        setExpOrgType([
            CONSTANTS.ORG_TYPE.REGISTERED,
            CONSTANTS.ORG_TYPE.CERTIFIED,
            CONSTANTS.ORG_TYPE.DEACTIVATED,
        ]);
    };
    useEffect(() => {
        if (expOrgType.length !== 0) {
            setIsRequired(false);
        } else {
            setIsRequired(true);
        }
    }, [expOrgType]);
    const exportProgramMeasureModal = () => {
        setExportProgramMeasureActivateInd(true);
        showModal();
    };
    useEffect(() => {
        if (filterInd) {
            const resetPagination = [
                setPendingPagination,
                setRegisteredPagination,
                setAwardedPagination,
                setDeactivatedPagination,
            ];
            resetPagination.forEach((setPaginations) =>
                setPaginations({ ...defaultPagination })
            );
            setFilterInd(false);
        }
    }, [filterInd]);
    const exportProgramMeasureDismissModal = () => {
        setExportProgramMeasureActivateInd(false);
        resetModalOnClose();
        showModal();
    };
    const getAwardDropdownData = () => {
        setIsAwardLoading(true);
        const awards = [{ label: "All", value: CONSTANTS.ALL_VALUE }];
        getAwardCategories(
            CONSTANTS.EMS_PROGRAM_ID,
            CONSTANTS.EMS_PROGRAM_CATEGORY_ID
        ).then((response) => {
            if (response?.data) {
                response.data.map((item: any) => {
                    awards.push({
                        label: item,
                        value: item,
                    });
                });
                const updatedAwards = [
                    ...awards,
                    { label: "No Award", value: "No Award" },
                    { label: "N/A", value: "N/A" },
                    { label: "None", value: "None" },
                ];
                setAwardCategories(updatedAwards);
                setIsAwardLoading(false);
            }
        });
    };

    function organizationFields() {
        const regFields: any = [{ label: "All", value: "All" }];
        setSelectedOrganizations([]);
        CONSTANTS.REGISTRATION.map((regField: any) => {
            regFields.push({
                label: regField.label,
                value: regField.value,
            });
        });
        setExpOrganizationFields(regFields);
        setIsOrganizationFieldsLoading(false);
        getAwardDropdownData();
    }

    useEffect(() => {
        organizationFields();
    }, []);

    useEffect(() => {
        if (!mounted) {
            getProgramFieldsById(
                CONSTANTS.EMS_PROGRAM_CATEGORY_ID,
                CONSTANTS.EMS_PROGRAM_ID
            ).then((response: any) => {
                if (response.success) {
                    const programFields: any = [{ label: "All", value: "All" }];
                    response.data.map((pgmField: any) => {
                        programFields.push({
                            label: wordChange(pgmField.title),
                            value: pgmField.key,
                        });
                    });
                    setExpProgramFields(programFields);
                    setMounted(true);
                }
            });
        }
        if (!clearFilters && localStorage.getItem("resetFilter") == "false") {
            getSearchedFacilities(searchFilter.searchKey, false, !mounted);
        }
    }, [tab]);

    useEffect(() => {
        if (typeof tab === "string" && tab.trim() !== "") {
            dispatch(setPageTab(tab));
            if (tab === CONSTANTS.PENDING) {
                setIsPending(true);
            }
        }
    }, [tab]);

    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Popover) {
            CONSTANTS.WINDOW.AUI.Popover.init();
        }
    });
    const isSafariBrowser = () => {
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            return true;
        }
        return false;
    };
    const eventHandler = (e: any) => {
        e.preventDefault();
    };

    const sortIndValue = (value: any) => {
        setSortInd(value);
    };

    const handleBulkRenew = (URL: any) => {
        const worker = new Worker(webWorkerScript);
        const WAIT_TIME = CONSTANTS.BULK_RENEW_WAIT_TIME;
        worker.addEventListener("message", (e: any) => {
            const type = e?.data?.type;
            const status = e?.data?.status;
            if (type === "apiResponse") {
                if (status === CONSTANTS.BULKRENEW_RESPONSE.IN_PROGRESS) {
                    worker.postMessage({
                        type: "start",
                        authToken: localStorage.getItem("userAccessToken"),
                        URL,
                        time: WAIT_TIME,
                    });
                } else if (status === CONSTANTS.BULKRENEW_RESPONSE.SUCCESS) {
                    dispatch(
                        setPageLoadingStatus({
                            isPageLoading: false,
                            isloadingWithMessage: false,
                        })
                    );
                    setBukRenewModalActiveInd(true);
                    setBulkRenewFailPopUp(true);
                    setBulkRenewPopupMsg("Bulk renewing has been completed.");
                    setBulkRenewModalHeader("Success");
                    setIsBulkRenewComplete(true);
                    showModal();
                } else if (status === CONSTANTS.BULKRENEW_RESPONSE.FAILED) {
                    dispatch(
                        setPageLoadingStatus({
                            isPageLoading: false,
                            isloadingWithMessage: false,
                        })
                    );
                    setBukRenewModalActiveInd(true);
                    setBulkRenewFailPopUp(true);
                    setBulkRenewPopupMsg(
                        "Bulk renewing has Failed. Please try again."
                    );
                    setBulkRenewModalHeader("Failed");
                    showModal();
                }
            }
        });
        worker.postMessage({
            type: "start",
            authToken: localStorage.getItem("userAccessToken"),
            URL,
            time: WAIT_TIME,
        });
    };

    const handleExport = (URL: any) => {
        const worker = new Worker(webWorkerScript);
        const WAIT_TIME = CONSTANTS.GLOBAL_EXPORTS_WAIT_TIME;
        setExportButtonDisabled(true);
        worker.addEventListener("message", (e: any) => {
            const type = e?.data?.type;
            const status = e?.data?.status;
            if (type === "apiResponse") {
                if (status === CONSTANTS.EXPORT_RESPONSE.IN_PROGRESS) {
                    localStorage.setItem("exportBtnRestrict", "true");
                    worker.postMessage({
                        type: "start",
                        authToken: localStorage.getItem("userAccessToken"),
                        URL,
                        time: WAIT_TIME,
                    });
                } else if (status === CONSTANTS.EXPORT_RESPONSE.SUCCESS) {
                    localStorage.setItem("exportBtnRestrict", "false");
                    setExportButtonDisabled(false);
                    gobalExportFile(
                        e?.data?.presignedUrl,
                        CONSTANTS.EMS_PROGRAM_NAME,
                        measuresDateFilter.year
                    );
                    const toast = {
                        message:
                            "Organization dashboard successfully exported as Excel.",
                        code: "Success:",
                        type: "success",
                    };
                    dispatch(showToast(toast));
                } else if (status === CONSTANTS.EXPORT_RESPONSE.FAILED) {
                    localStorage.setItem("exportBtnRestrict", "false");
                    setExportButtonDisabled(false);
                    const msg = document.getElementById(
                        "faild-message"
                    ) as HTMLElement;
                    if (msg) {
                        msg.innerHTML = "";
                    }
                    document
                        .getElementById("faild-message")
                        ?.append(
                            "Organization export is failed, Please try again!"
                        );
                    const alertPopup = document.getElementById("alertPopup");
                    alertPopup?.classList.remove("popupHide");
                }
            }
        });
        const toast = {
            message:
                "Organization measure export is in progress. Please don't refresh this page.",
            code: "Success:",
            type: "warning",
        };
        dispatch(showToast(toast));
        worker.postMessage({
            type: "start",
            authToken: localStorage.getItem("userAccessToken"),
            URL,
            time: WAIT_TIME,
        });
    };
    const handleExportToExcelEvent = (e: any) => {
        setExportButtonState(true);
        let finalOrganizations: any = [];
        let finalPprograms: any = [];

        finalOrganizations = selectedOrganizations
            .filter((itm: any) => itm.value != "All")
            .map((val: any) => val.value);
        finalPprograms = selectedPrograms
            .filter((itm: any) => itm.value != "All")
            .map((val: any) => val.value);
        e.preventDefault();
        const exportPayload: any = {
            year: measuresDateFilter.year,
            type: expOrgType,
            registrationFields: finalOrganizations,
            programFields: finalPprograms,
            benchmarks: "false",
            programCategoryName: CONSTANTS.EMS_PROGRAM_NAME,
            Interval: [CONSTANTS.ANNUAL, CONSTANTS.QUARTERLY],
        };
        const payload = JSON.parse(JSON.stringify(exportPayload));
        const expProgram = {
            programId: CONSTANTS.EMS_PROGRAM_ID,
            categoryId: CONSTANTS.EMS_PROGRAM_CATEGORY_ID,
        };
        getPgmMeasuresExportExcel(expProgram, payload)
            .then((response) => {
                setExportButtonState(false);
                exportProgramMeasureDismissModal();
                const URL = `/admin/adminFacilityExports/${response.data.exportId}`;
                handleExport(URL);
            })
            .catch(() => {
                setExportButtonState(false);
            });
    };
    const [certifiedHospitals, setCertifiedHospitals] = useState<any>([]);
    const [pagination, setPagination] =
        useState<IPagination>(defaultPagination);
    const getHospitalData = (mountedInd: any) => {
        getHospitalsForAdminManage({
            status: tab,
            pageNumber,
            region: CONSTANTS.DOMESTIC,
            pageSize: pagination.pageSize,
            isActive,
            categoryId: CONSTANTS.EMS_PROGRAM_CATEGORY_ID,
            country,
            sortInd,
            stateCd,
            awardCd,
            measureCd,
            searchKey: searchFilter.searchKey,
        }).then((response: any) => {
            if (mountedInd && response.success && response.statusCode === 200) {
                if (tab === CONSTANTS.PENDING) {
                    setNegativeTabIndex(
                        [
                            "certified-tab",
                            "registered-tab",
                            "deactivated-tab",
                            "pending-tab",
                        ],
                        "pending-tab"
                    );
                    setPendingPagination({
                        ...response.data._pagination,
                        setPageNumber,
                    });
                    setPendingHospitals(response.data.hospitalPrograms);
                } else if (tab === CONSTANTS.REGISTRED) {
                    setNegativeTabIndex(
                        [
                            "certified-tab",
                            "registered-tab",
                            "deactivated-tab",
                            "pending-tab",
                        ],
                        "registered-tab"
                    );
                    setRegisteredPagination({
                        ...response.data._pagination,
                        setPageNumber,
                    });
                    setRegisteredHospitals(response.data.hospitalPrograms);
                } else if (tab === CONSTANTS.CERTIFIED) {
                    setNegativeTabIndex(
                        [
                            "certified-tab",
                            "registered-tab",
                            "deactivated-tab",
                            "pending-tab",
                        ],
                        "certified-tab"
                    );
                    setAwardedPagination({
                        ...response.data._pagination,
                        setPageNumber,
                    });
                    setCertifiedHospitals(response.data.hospitalPrograms);
                } else {
                    setNegativeTabIndex(
                        [
                            "certified-tab",
                            "registered-tab",
                            "deactivated-tab",
                            "pending-tab",
                        ],
                        "deactivated-tab"
                    );
                    setDeactivatedPagination({
                        ...response.data._pagination,
                        setPageNumber,
                    });
                    setDeactivatedHospitals(response.data.hospitalPrograms);
                }
            }
            setIsTableLoading(false);
            setRoleValue("alert");
        });
    };
    useEffect(() => {
        let mountedInd = true;
        setIsTableLoading(true);
        if (mountedInd) {
            getHospitalData(mountedInd);
        }
        window.scrollTo(0, 0);
        return function cleanup() {
            mountedInd = false;
        };
    }, [pageNumber, tab]);

    const getServiceYearAndCounts = () => {
        setIsActiveServiceYear(true);
        Promise.all([getServiceYear(), getRegisteredAndAwardedCount()]).then(
            (responses: any) => {
                if (responses[0].success && responses[0].data) {
                    setActiveServiceYear(
                        responses[0].data.activeServiceYear.year
                    );
                    setIsActiveServiceYear(false);
                }
                if (responses[1].success && responses[1].data) {
                    setFacilityRegCount(responses[1].data.registeredCount);
                    setAwardedCount(responses[1].data.awardedCount);
                }
            }
        );
    };

    useEffect(() => {
        const states = [{ label: "All", value: CONSTANTS.ALL }];
        getStates("US").then((response) => {
            if (response?.data) {
                response.data.map((item: any) => {
                    states.push({
                        label: item.name,
                        value: item.name,
                    });
                });
                setStateDropdown(states);
                setIsStateLoading(false);
            }
        });
        getServiceYearAndCounts();
        window.onbeforeunload = function () {
            history.replace();
            return undefined;
        };
        return () => {
            window.onbeforeunload = null;
        };
    }, []);

    const close = () => {
        history.push("/emsadmin/organization-management");
    };
    const getSearchedFacilities = (
        value: string,
        clearFilterInd?: boolean,
        retainPageInd?: boolean
    ) => {
        setIsTableLoading(true);
        if (!retainPageInd) {
            setPageNumber(1);
            setPagination(defaultPagination);
        }
        getHospitalsForAdminManage({
            status: tab,
            pageNumber: retainPageInd ? pageNumber : 1,
            region: CONSTANTS.DOMESTIC,
            pageSize: pagination.pageSize,
            isActive,
            categoryId: CONSTANTS.EMS_PROGRAM_CATEGORY_ID,
            sortInd,
            stateCd: clearFilterInd ? CONSTANTS.ALL : stateCd,
            awardCd: clearFilterInd ? CONSTANTS.ALL : awardCd,
            measureCd: clearFilterInd ? CONSTANTS.ALL : measureCd,
            searchKey: value,
        }).then((response: any) => {
            if (response.success && response.statusCode === 200) {
                if (tab === CONSTANTS.PENDING) {
                    setNegativeTabIndex(
                        [
                            "certified-tab",
                            "registered-tab",
                            "deactivated-tab",
                            "pending-tab",
                        ],
                        "pending-tab"
                    );
                    setPendingPagination({
                        ...response.data._pagination,
                        setPageNumber,
                    });
                    setPendingHospitals(response.data.hospitalPrograms);
                } else if (tab === CONSTANTS.REGISTRED) {
                    setNegativeTabIndex(
                        [
                            "certified-tab",
                            "registered-tab",
                            "deactivated-tab",
                            "pending-tab",
                        ],
                        "registered-tab"
                    );
                    setRegisteredPagination({
                        ...response.data._pagination,
                        setPageNumber,
                    });
                    setRegisteredHospitals(response.data.hospitalPrograms);
                } else if (tab === CONSTANTS.CERTIFIED) {
                    setNegativeTabIndex(
                        [
                            "certified-tab",
                            "registered-tab",
                            "deactivated-tab",
                            "pending-tab",
                        ],
                        "certified-tab"
                    );
                    setAwardedPagination({
                        ...response.data._pagination,
                        setPageNumber,
                    });
                    setCertifiedHospitals(response.data.hospitalPrograms);
                } else {
                    setNegativeTabIndex(
                        [
                            "certified-tab",
                            "registered-tab",
                            "deactivated-tab",
                            "pending-tab",
                        ],
                        "deactivated-tab"
                    );
                    setDeactivatedPagination({
                        ...response.data._pagination,
                        setPageNumber,
                    });
                    setDeactivatedHospitals(response.data.hospitalPrograms);
                }
            }
            setIsTableLoading(false);
            setClearFilters(false);
            if (mounted) {
                setRoleValue("alert");
            }
        });
    };
    const onkeyDownCallback = (event: any, name: string, total: number) => {
        let nextTarget;
        if (event.keyCode == 39) {
            // right arrow
            const activeEle: any = document.activeElement;
            if (parseInt(activeEle?.name?.split(name)[1]) == total) {
                nextTarget = 1;
            } else {
                nextTarget = parseInt(activeEle?.name?.split(name)[1]) + 1;
            }
            const ele: any = document.querySelectorAll(
                `button[name="tab_${nextTarget}"]`
            )[0];
            ele.focus();
        } else if (event.keyCode == 37) {
            // left arrow
            const activeEle: any = document.activeElement;
            if (parseInt(activeEle?.name?.split(name)[1]) == 1) {
                nextTarget = 4;
            } else {
                nextTarget = parseInt(activeEle?.name?.split(name)[1]) - 1;
            }
            const ele: any = document.querySelectorAll(
                `button[name="tab_${nextTarget}"]`
            )[0];
            ele.focus();
        }
    };
    const [awardVal, setAwardVal] = useState<any>(searchFilter.award);
    const [measureVal, setMeasureVal] = useState<any>(searchFilter.measure);
    const setDefaultPagination = () => {
        setPageNumber(1);
        setPagination(defaultPagination);
    };
    useEffect(() => {
        if (awardCd) {
            setAwardVal(awardCd !== undefined ? awardCd : searchFilter.award);
        }
        if (measureCd) {
            setMeasureVal(
                measureCd !== undefined ? measureCd : searchFilter.measure
            );
        }
    }, [awardCd, measureCd]);
    const getFacilitiesResult = ({
        award,
        stateCode,
        measures,
        search,
        sortIndVal,
    }: any) => {
        setIsTableLoading(true);
        setPagination(defaultPagination);
        setAwardCd(award !== undefined ? award : searchFilter.award);
        setMeasureCd(measures !== undefined ? measures : searchFilter.measure);
        setStateCd(
            stateCode !== undefined ? stateCode : searchFilter.stateCode
        );
        setSearchValue(search !== undefined ? search : searchFilter.searchKey);
        getHospitalsForAdminManage({
            status: tab,
            pageNumber: 1,
            region: CONSTANTS.DOMESTIC,
            categoryId: CONSTANTS.EMS_PROGRAM_CATEGORY_ID,
            pageSize: pagination.pageSize,
            isActive,
            sortInd: sortIndVal === null ? sortIndVal : sortInd,
            awardCd: award !== undefined ? award : searchFilter.award,
            measureCd: measures !== undefined ? measures : searchFilter.measure,
            stateCd:
                stateCode !== undefined ? stateCode : searchFilter.stateCode,
            searchKey: search !== undefined ? search : searchFilter.searchKey,
        }).then((response: any) => {
            setIsSearchResult(true);
            if (response.success && response.statusCode === 200) {
                switch (tab) {
                    case CONSTANTS.PENDING:
                        setNegativeTabIndex(
                            [
                                "certified-tab",
                                "registered-tab",
                                "deactivated-tab",
                                "pending-tab",
                            ],
                            "pending-tab"
                        );
                        setPendingPagination({
                            ...response.data._pagination,
                            setPageNumber,
                        });
                        setPendingHospitals(response.data.hospitalPrograms);
                        break;
                    case CONSTANTS.REGISTRED:
                        setNegativeTabIndex(
                            [
                                "certified-tab",
                                "registered-tab",
                                "deactivated-tab",
                                "pending-tab",
                            ],
                            "registered-tab"
                        );
                        setRegisteredPagination({
                            ...response.data._pagination,
                            setPageNumber,
                        });
                        setRegisteredHospitals(response.data.hospitalPrograms);
                        break;
                    case CONSTANTS.CERTIFIED:
                        setNegativeTabIndex(
                            [
                                "certified-tab",
                                "registered-tab",
                                "deactivated-tab",
                                "pending-tab",
                            ],
                            "certified-tab"
                        );
                        setAwardedPagination({
                            ...response.data._pagination,
                            setPageNumber,
                        });
                        setCertifiedHospitals(response.data.hospitalPrograms);
                        break;
                    default:
                        setNegativeTabIndex(
                            [
                                "certified-tab",
                                "registered-tab",
                                "deactivated-tab",
                                "pending-tab",
                            ],
                            "deactivated-tab"
                        );
                        setDeactivatedPagination({
                            ...response.data._pagination,
                            setPageNumber,
                        });
                        setDeactivatedHospitals(response.data.hospitalPrograms);
                        break;
                }
            }
            setIsTableLoading(false);
            setClearFilters(false);
            if (mounted) {
                setRoleValue("alert");
            }
        });
    };

    useEffect(() => {
        if (!mounted) {
            if (localStorage.getItem("resetFilter") == "true") {
                dispatch(
                    setProgramCategoryId(CONSTANTS.EMS_PROGRAM_CATEGORY_ID)
                );
                dispatch(setSearchKey(""));
                dispatch(setStateCode(CONSTANTS.ALL));
                dispatch(setAward(CONSTANTS.ALL));
                dispatch(setMeasure(CONSTANTS.ALL));
                getSearchedFacilities("", true);
            }
        }
        if (!clearFilters && localStorage.getItem("resetFilter") == "false") {
            getSearchedFacilities(searchFilter.searchKey, false, !mounted);
        }
    }, [tab]);

    useEffect(() => {
        if (activeServiceYear) {
            setIsActiveServiceYear(true);
            const currentDate = new Date();
            const cstTimeZone = "America/Chicago";
            const currentCstDate = currentDate.toLocaleDateString("en-US", {
                timeZone: cstTimeZone,
            });
            const Year = new Date(currentCstDate).getFullYear();
            setPresentYear(Year);
            setLastYear((Year - 1).toString());
            const startDate = `${activeServiceYear}-01-01`;
            const endDate = `${activeServiceYear}-12-31`;
            const formattedCstDate = new Date(currentCstDate);
            const month = (formattedCstDate.getMonth() + 1)
                .toString()
                .padStart(2, "0");
            const minDate = `${formattedCstDate.getFullYear()}-${month}-${formattedCstDate.getDate()}`;
            setMinDateForLock(minDate);
            getMeasureLockData(startDate, endDate).then((response: any) => {
                if (response.data) {
                    const res = response.data;
                    const lockDate = res?.measureLockDate
                        ? new Date(res.measureLockDate)
                              .toISOString()
                              .split("T")[0]
                        : `${activeServiceYear + 1}-12-31`;
                    setMeasureLockDate(lockDate);
                    if (res.isMeasureLocked == true) {
                        setMeasureLockInd(true);
                    } else {
                        setMeasureLockInd(false);
                    }
                    setIsActiveServiceYear(false);
                }
            });
        }
    }, [activeServiceYear]);

    useEffect(() => {
        if (clearFilters) {
            setIsSearchResult(true);
        } else {
            setIsSearchResult(false);
        }
    }, [clearFilters]);

    const lockUnlockModal = () => {
        setlockUnlockActivateInd(true);
        showModal();
    };
    const lockUnlockDismissModal = () => {
        setlockUnlockActivateInd(false);
        setLockUpdateButtonState(true);
        showModal();
    };

    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Tooltip) {
            CONSTANTS.WINDOW.AUI.Tooltip.init();
        }
    });
    const handleLockUnlockMeasures = (event: any, lockInd: boolean) => {
        event.preventDefault();
        let updatedLockDate = modifiedLockDate;
        if (!modifiedLockDate && !lockInd) {
            updatedLockDate = `${presentYear}-12-31`;
        } else if (!modifiedLockDate && lockInd) {
            updatedLockDate = measureLockDate;
        }
        setLockUpdateButtonSpinnerState(true);
        const reqBody = { measureLock: true, lockDate: updatedLockDate };
        updateLockMeasures(reqBody)
            .then((response: any) => {
                if (response.success) {
                    setLockUpdateButtonSpinnerState(false);
                    setMeasureLockDate(updatedLockDate);
                    if (lockInd) {
                        setMeasureLockInd(false);
                    }
                    lockUnlockDismissModal();
                    const toast = {
                        message: `All organizations will be locked to enter measures after ${localDateToYYYYMMDDFormatString(
                            updatedLockDate
                        )}`,
                        code: "Success:",
                        type: "success",
                    };
                    store.dispatch(showToast(toast));
                }
            })
            .catch((error: any) => {
                setLockUpdateButtonSpinnerState(false);
                const tody = new Date();
                let yesterday: any = dateToYYYYMMDDFormatString(
                    tody.setDate(tody.getDate() - 1)
                );
                yesterday = cstDateFormat(yesterday);
                let msg: any = "";
                if (activeServiceYear + 1 > new Date().getFullYear()) {
                    msg = `Please enter a valid lock date in yyyy-mmm-dd format greater than ${yesterday}`;
                } else {
                    msg = `Please enter a valid lock date in yyyy-mmm-dd format greater than yesterday's ${yesterday}`;
                }

                const toast = {
                    message: msg,
                    code: "Error:",
                };
                if (error.message === "Invalid Lock Date") {
                    dispatch(showToast(toast));
                } else if (activeServiceYear + 1 > new Date().getFullYear()) {
                    yesterday = cstDateFormat(`${activeServiceYear + 1}-01-01`);
                    dispatch(
                        showToast({
                            message: `Enter a valid date after ${yesterday} in the year ${
                                activeServiceYear + 1
                            }.`,
                        })
                    );
                } else {
                    dispatch(
                        showToast({
                            message: `Enter a valid date after ${yesterday}-01-01 in the year ${
                                activeServiceYear + 1
                            }.`,
                        })
                    );
                }
            });
    };

    const modifyLockDate = (date: any) => {
        try {
            setLockUpdateButtonState(false);
            setModifiedLockDate(date);
        } catch (error) {
            const tody = new Date();
            let yesterday: any = dateToYYYYMMDDFormatString(
                tody.setDate(tody.getDate() - 1)
            );
            yesterday = cstDateFormat(yesterday);
            const toast = {
                message: `Please enter a valid lock date in yyyy-mmm-dd format greater than yesterday's ${yesterday}`,
                code: "Error:",
            };
            dispatch(showToast(toast));
        }
    };
    const updateBtnInd = (state: any) => {
        setLockUpdateButtonState(state);
    };
    useEffect(() => {
        localStorage.setItem("measureLockInd", JSON.stringify(measureLockInd));
    }, [measureLockInd]);
    const sortDropdown = [
        {
            label: "Export",
            value: "Export",
            className: "aha-icon-export font-red mr-2",
        },
        {
            label: "Bulk Renew",
            value: "Bulk renew",
            className: "aha-icon-renew font-red mr-2",
        },
    ];
    const exportInProgressNotify = () => {
        const toast = {
            message: "Please wait for the current export to complete.",
            code: "Success:",
            type: "warning",
        };
        dispatch(showToast(toast));
    };
    const triggerOptionOnSelect = (value: any) => {
        if (value === CONSTANTS.EXPORT) {
            return !exportButtonDisabled
                ? exportProgramMeasureModal()
                : exportInProgressNotify();
        }
        if (value === CONSTANTS.BULK_RENEW) {
            if (!measureLockInd) {
                const msg = (
                    <span>
                        Please enable the measure lock for service year{" "}
                        {activeServiceYear} to renew the organization for{" "}
                        {activeServiceYear + 1} year award
                    </span>
                );
                setBulkRenewPopupMsg(msg);
                setBulkRenewModalHeader("Warning");
            } else if (facilityRegCount > 0) {
                const msg: any = (
                    <span>
                        Please close the {facilityRegCount} organizations in the
                        registered tab to renew the organization for{" "}
                        {activeServiceYear + 1} year award
                    </span>
                );
                setBulkRenewPopupMsg(msg);
                setBulkRenewModalHeader("Warning");
            } else {
                setBulkRenewPopupMsg(
                    `Are you sure you want to proceed with renewing ${awardedCount} Awarded organization and move them to Registered ?`
                );
                setBulkRenewModalHeader("Confirm Organization Renewal");
            }
            openBulkRenewalModal();
        }
    };
    const openBulkRenewalModal = () => {
        setBukRenewModalActiveInd(true);
        showModal();
    };
    const dismissBulkRenewModal = () => {
        setBukRenewModalActiveInd(false);
        showModal();
    };
    const activeTabAfterBulkRenew = () => {
        setPageNumber(1);
        setPagination(defaultPagination);
        setTab(CONSTANTS.REGISTRED);
        setIsActive(true);
    };
    useEffect(() => {
        let mountedInd = true;
        if (isBulkRenewComplete) {
            setIsTableLoading(true);
            if (mountedInd) {
                getHospitalData(mountedInd);
            }
            window.scrollTo(0, 0);
            activeTabAfterBulkRenew();
            getServiceYearAndCounts();
        }
        return function cleanup() {
            mountedInd = false;
        };
    }, [isBulkRenewComplete]);

    const confirmBulkRenew = (e: any) => {
        dismissBulkRenewModal();
        dispatch(
            setPageLoadingStatus({
                isPageLoading: true,
                isloadingWithMessage: true,
                message: "Bulk Renewing is in progress.",
            })
        );
        e.preventDefault();
        setBulkRenewFailPopUp(true);
        bulkRenewFacility(activeServiceYear)
            .then((response: any) => {
                if (response.success) {
                    const URL = `/lambda/processId/${response.data.processId}`;
                    handleBulkRenew(URL);
                }
            })
            .catch(() => {
                setBulkRenewFailPopUp(true);
                dispatch(
                    setPageLoadingStatus({
                        isPageLoading: false,
                        isloadingWithMessage: false,
                    })
                );
            });
    };

    return (
        <OrganizationManagementWrapper className="container-fluid p-0 d-flex flex-grow-1">
            <div className="row no-gutters w-100 flex-grow-1">
                <Sidebar activeTab="organizationManagement" />

                <section className="d-flex admin-main-content container">
                    <div className="row no-gutters w-100 admin-main">
                        <div className="col-12">
                            <div className="d-md-flex justify-content-between dashboard-fac-magnt align-items-center">
                                <h1 className="h2 font-400 mb-0">
                                    Organization Management{" "}
                                    {activeServiceYear && (
                                        <span className="serviceYear">
                                            Service Year - {activeServiceYear}
                                        </span>
                                    )}
                                </h1>
                                <div className="d-flex mt-3 mt-md-0">
                                    <div
                                        id="lock-unlock"
                                        className="lock-unlock mr-2"
                                    >
                                        <button
                                            className="btn btn-text font-red global-lock-btn"
                                            data-toggle="modal"
                                            data-target="#selctedFacilityModal"
                                            onClick={lockUnlockModal}
                                            aria-describedby="globalLock"
                                            disabled={isActiveServiceYear}
                                        >
                                            {measureLockInd ? (
                                                <i className="aha-icon-locked mr-1 lock-admin-icon" />
                                            ) : (
                                                <i className="aha-icon-unlocked lock-admin-icon mr-1" />
                                            )}
                                            Measure Lock
                                        </button>
                                        <div
                                            className="tooltip fade show bs-tooltip-bottom global-lock-tooltip"
                                            role="tooltip"
                                            id="globalLock"
                                        >
                                            <div className="arrow" />
                                            <div className="tooltip-inner">
                                                {measureLockInd
                                                    ? `Measure locked on ${cstDateFormat(
                                                          measureLockDate
                                                      )}. Update if required.`
                                                    : `Locking Measures on ${cstDateFormat(
                                                          measureLockDate
                                                      )}. Update if required.`}
                                            </div>
                                        </div>
                                        {lockUnlockActivateInd && (
                                            <FocusTrap
                                                focusTrapOptions={{
                                                    escapeDeactivates: false,
                                                    clickOutsideDeactivates:
                                                        false,
                                                }}
                                            >
                                                <div
                                                    className="modal fade show aui-modal lock-unlock-modal"
                                                    id="lockUnlockModal"
                                                    tabIndex={-1}
                                                    aria-labelledby="lockUnlockLabel"
                                                    aria-modal="true"
                                                    role="dialog"
                                                    style={{
                                                        display:
                                                            lockUnlockActivateInd
                                                                ? "block"
                                                                : "none",
                                                    }}
                                                >
                                                    <div className="modal-dialog modal-dialog-centered modal-md">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h2
                                                                    className="h5"
                                                                    id="lockUnlockLabel"
                                                                    aria-label={`Restrict Organization Measures hyphen ${lastYear}`}
                                                                >
                                                                    Restrict
                                                                    Organization
                                                                    Measures -{" "}
                                                                    {
                                                                        activeServiceYear
                                                                    }{" "}
                                                                    Service Year
                                                                </h2>
                                                                <button
                                                                    id="closeExportModal"
                                                                    type="button"
                                                                    className="close"
                                                                    onClick={
                                                                        lockUnlockDismissModal
                                                                    }
                                                                    aria-label="Close Restrict Organizations Measures model"
                                                                >
                                                                    <span
                                                                        aria-hidden="true"
                                                                        className="aha-icon-cross"
                                                                    />
                                                                </button>
                                                            </div>
                                                            <div className="modal-body p-0">
                                                                {measureLockInd ? (
                                                                    <form
                                                                        onSubmit={(
                                                                            event
                                                                        ) =>
                                                                            handleLockUnlockMeasures(
                                                                                event,
                                                                                true
                                                                            )
                                                                        }
                                                                    >
                                                                        <p
                                                                            className="lock-unlock-info"
                                                                            role="note"
                                                                        >
                                                                            Update
                                                                            measure
                                                                            lock
                                                                            date
                                                                            for
                                                                            all
                                                                            organizations
                                                                        </p>
                                                                        <div className="form-group d-flex flex-column required">
                                                                            <label
                                                                                htmlFor="unlockdate"
                                                                                className="mb-2"
                                                                                id="lockdate-label"
                                                                            >
                                                                                Update
                                                                                the
                                                                                lock
                                                                                date
                                                                            </label>
                                                                            <Datepicker
                                                                                id="unlockdate"
                                                                                defaultValue={
                                                                                    measureLockDate
                                                                                }
                                                                                onChangeDate={
                                                                                    modifyLockDate
                                                                                }
                                                                                setBtnInd={
                                                                                    updateBtnInd
                                                                                }
                                                                                ariaLabel="lockdate-label"
                                                                                minMaxDate
                                                                                isCstCurrentDate
                                                                                activeServiceYear={
                                                                                    activeServiceYear
                                                                                }
                                                                            />
                                                                            <div
                                                                                id="lockdateinfo"
                                                                                className="mt-1 font-14"
                                                                            >
                                                                                <i
                                                                                    className="aha-icon-info font-red mr-1 ml-n1"
                                                                                    aria-hidden="true"
                                                                                />
                                                                                Lock
                                                                                will
                                                                                be
                                                                                applied
                                                                                at
                                                                                23:59:59
                                                                                CST
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex justify-content-end lock-unlock-btns">
                                                                            <button
                                                                                id="lockCancel"
                                                                                className="btn btn-secondary btn-round"
                                                                                type="button"
                                                                                onClick={
                                                                                    lockUnlockDismissModal
                                                                                }
                                                                            >
                                                                                Cancel
                                                                            </button>
                                                                            <button
                                                                                className={`btn btn-primary btn-round ${
                                                                                    lockUpdateButtonSpinnerState
                                                                                        ? CONSTANTS.BUTTON_SPINNER
                                                                                        : ""
                                                                                }`}
                                                                                type="submit"
                                                                                disabled={
                                                                                    lockUpdateButtonState
                                                                                }
                                                                            >
                                                                                Update
                                                                            </button>
                                                                        </div>
                                                                    </form>
                                                                ) : (
                                                                    <form
                                                                        onSubmit={(
                                                                            event
                                                                        ) =>
                                                                            handleLockUnlockMeasures(
                                                                                event,
                                                                                false
                                                                            )
                                                                        }
                                                                    >
                                                                        <p
                                                                            className="lock-unlock-info"
                                                                            role="note"
                                                                        >
                                                                            Update
                                                                            measure
                                                                            lock
                                                                            date
                                                                            for
                                                                            all
                                                                            organizations
                                                                        </p>
                                                                        <div className="form-group d-flex flex-column required">
                                                                            <label
                                                                                htmlFor="lockdate"
                                                                                className="mb-2"
                                                                                id="lockdate-label"
                                                                            >
                                                                                Update
                                                                                lock
                                                                                date
                                                                            </label>
                                                                            <Datepicker
                                                                                id="lockdate"
                                                                                defaultValue={
                                                                                    measureLockDate
                                                                                }
                                                                                onChangeDate={
                                                                                    modifyLockDate
                                                                                }
                                                                                setBtnInd={
                                                                                    updateBtnInd
                                                                                }
                                                                                ariaLabel="lockdate-label"
                                                                                minMaxDate
                                                                                isCstCurrentDate
                                                                                activeServiceYear={
                                                                                    activeServiceYear
                                                                                }
                                                                            />
                                                                            <div
                                                                                id="lockdateinfo"
                                                                                className="mt-1 font-14"
                                                                            >
                                                                                <i
                                                                                    className="aha-icon-info font-red mr-1 ml-n1"
                                                                                    aria-hidden="true"
                                                                                />
                                                                                Lock
                                                                                will
                                                                                be
                                                                                applied
                                                                                at
                                                                                23:59:59
                                                                                CST
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex justify-content-end lock-unlock-btns">
                                                                            <button
                                                                                className="btn btn-secondary btn-round"
                                                                                type="button"
                                                                                onClick={
                                                                                    lockUnlockDismissModal
                                                                                }
                                                                                aria-label="Cancel Restrict Organization Measures"
                                                                            >
                                                                                Cancel
                                                                            </button>
                                                                            <button
                                                                                className={`btn btn-primary btn-round ${
                                                                                    lockUpdateButtonSpinnerState
                                                                                        ? CONSTANTS.BUTTON_SPINNER
                                                                                        : ""
                                                                                }`}
                                                                                type="submit"
                                                                                disabled={
                                                                                    lockUpdateButtonState
                                                                                }
                                                                            >
                                                                                Update
                                                                            </button>
                                                                        </div>
                                                                    </form>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </FocusTrap>
                                        )}
                                    </div>
                                    {"   "}
                                    <MoreActions
                                        sortDropdown={sortDropdown}
                                        callParentOnSelect={
                                            triggerOptionOnSelect
                                        }
                                        vIcon="aha-icon-kebab"
                                    />
                                </div>
                            </div>
                            {exportProgramMeasureActivateInd && (
                                <FocusTrap
                                    focusTrapOptions={{
                                        escapeDeactivates: false,
                                        clickOutsideDeactivates: false,
                                    }}
                                >
                                    <div
                                        className="modal fade show aui-modal"
                                        id="exportProgramMeasuresModal"
                                        tabIndex={-1}
                                        aria-labelledby="exportProgramMeasuresLabel"
                                        aria-modal="true"
                                        role="dialog"
                                        style={{
                                            display:
                                                exportProgramMeasureActivateInd
                                                    ? "block"
                                                    : "none",
                                        }}
                                    >
                                        <div className="modal-dialog modal-dialog-centered modal-md">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h2
                                                        className="h4"
                                                        id="exportProgramMeasuresLabel"
                                                    >
                                                        Export Program Measures
                                                    </h2>
                                                    <button
                                                        id="closeExportModal"
                                                        type="button"
                                                        className="close"
                                                        onClick={
                                                            exportProgramMeasureDismissModal
                                                        }
                                                        aria-label="Close export program measures modal"
                                                    >
                                                        <span
                                                            aria-hidden="true"
                                                            className="aha-icon-cross"
                                                        />
                                                    </button>
                                                </div>
                                                <div className="modal-body p-0">
                                                    <form
                                                        onSubmit={
                                                            handleExportToExcelEvent
                                                        }
                                                    >
                                                        <div className="d-flex flex-row-reverse">
                                                            <div className="pb-3 mand-field">
                                                                <sup>*</sup>
                                                                mandatory fields
                                                            </div>
                                                        </div>
                                                        <div
                                                            role="group"
                                                            aria-labelledby="organization-export"
                                                            className="form-group required"
                                                        >
                                                            <label
                                                                className="pb-2 mb-0 font-600 label-form"
                                                                id="organization-export"
                                                            >
                                                                Select
                                                                organization
                                                                type(s) to
                                                                export
                                                                <sup>*</sup>
                                                            </label>
                                                            <div className="form-check mb-3 mb-sm-2 px-0">
                                                                <ul className="d-sm-flex p-0">
                                                                    <li className="form-check mb-3 mb-sm-2">
                                                                        <input
                                                                            name="registered"
                                                                            type="checkbox"
                                                                            value="Registred"
                                                                            checked={expOrgType.includes(
                                                                                CONSTANTS
                                                                                    .ORG_TYPE
                                                                                    .REGISTERED
                                                                            )}
                                                                            id="registered"
                                                                            onChange={
                                                                                onChangeExpOrgType
                                                                            }
                                                                            required={
                                                                                isRequired
                                                                            }
                                                                        />
                                                                        <label htmlFor="registered">
                                                                            Registered
                                                                        </label>
                                                                    </li>
                                                                    <li className="form-check mb-3 mb-sm-2 ml-sm-4">
                                                                        <input
                                                                            name="certified"
                                                                            type="checkbox"
                                                                            value="Certified"
                                                                            checked={expOrgType.includes(
                                                                                CONSTANTS
                                                                                    .ORG_TYPE
                                                                                    .CERTIFIED
                                                                            )}
                                                                            id="certified"
                                                                            onChange={
                                                                                onChangeExpOrgType
                                                                            }
                                                                            required={
                                                                                isRequired
                                                                            }
                                                                        />
                                                                        <label htmlFor="certified">
                                                                            Awarded
                                                                        </label>
                                                                    </li>
                                                                    <li className="form-check mb-3 mb-sm-2 ml-sm-4">
                                                                        <input
                                                                            name="deactivated"
                                                                            type="checkbox"
                                                                            value="Dectivated"
                                                                            checked={expOrgType.includes(
                                                                                CONSTANTS
                                                                                    .ORG_TYPE
                                                                                    .DEACTIVATED
                                                                            )}
                                                                            id="deactivated"
                                                                            onChange={
                                                                                onChangeExpOrgType
                                                                            }
                                                                            required={
                                                                                isRequired
                                                                            }
                                                                        />
                                                                        <label htmlFor="deactivated">
                                                                            Deactivated
                                                                        </label>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="form-group required flex-column my-4">
                                                            <div className="service-year">
                                                                <ServiceYearDropdown
                                                                    id="exportServiceYear"
                                                                    exportEmsInd
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group flex-column my-4">
                                                            <label
                                                                className="mb-1"
                                                                id="dropdown-label-orgDetailsExport"
                                                            >
                                                                Select the
                                                                organization
                                                                details to
                                                                export
                                                                (optional)
                                                            </label>
                                                            <GroupedMultiSelectDropdown
                                                                hasAllOption
                                                                id="orgDetailsExport"
                                                                items={
                                                                    expOrganizationFields
                                                                }
                                                                selectedValue={
                                                                    selectedOrganizations
                                                                }
                                                                callParentOnSelect={(
                                                                    value: any
                                                                ) => {
                                                                    setSelectedOrganizations(
                                                                        value
                                                                    );
                                                                }}
                                                                isLoading={
                                                                    isOrganizationFieldsLoading
                                                                }
                                                            />
                                                        </div>
                                                        <div className="form-group flex-column my-4">
                                                            <label
                                                                className="mb-1"
                                                                id="dropdown-label-orgProgramsExport"
                                                            >
                                                                Select the
                                                                program details
                                                                to export
                                                                (optional)
                                                            </label>
                                                            <GroupedMultiSelectDropdown
                                                                hasAllOption
                                                                id="orgProgramsExport"
                                                                items={
                                                                    expProgramFields
                                                                }
                                                                selectedValue={
                                                                    selectedPrograms
                                                                }
                                                                callParentOnSelect={(
                                                                    value: any
                                                                ) => {
                                                                    setSelectedPrograms(
                                                                        value
                                                                    );
                                                                }}
                                                                isLoading={
                                                                    isProgramFieldsLoading
                                                                }
                                                            />
                                                        </div>
                                                        <div className="d-flex justify-content-end mt-4 flex-column flex-sm-row ">
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary btn-round mr-sm-4"
                                                                onClick={
                                                                    exportProgramMeasureDismissModal
                                                                }
                                                                aria-label="cancel and close export program measures modal"
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                disabled={
                                                                    exportButtonState
                                                                }
                                                                type="submit"
                                                                className={`btn btn-primary btn-round  mt-4  mt-sm-0 ${
                                                                    exportButtonState
                                                                        ? CONSTANTS.BUTTON_SPINNER
                                                                        : ""
                                                                }`}
                                                            >
                                                                <i className="aha-icon-export export-admin-icon mr-1" />
                                                                Export
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </FocusTrap>
                            )}
                            <div>
                                <ul
                                    className="nav nav-tabs tab-filled"
                                    id="myTab"
                                    role="tablist"
                                >
                                    <li
                                        className="nav-item tab-filled-item"
                                        role="presentation"
                                    >
                                        <button
                                            name="tab_1"
                                            onKeyDown={(event) => {
                                                onkeyDownCallback(
                                                    event,
                                                    "tab_",
                                                    4
                                                );
                                            }}
                                            className={`nav-link ${
                                                tab === CONSTANTS.PENDING
                                                    ? "active"
                                                    : ""
                                            }`}
                                            id="pending-tab"
                                            data-toggle="tab"
                                            data-target="#pending-approval-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="pending-approval-tab"
                                            aria-selected="true"
                                            onClick={() => {
                                                if (tab !== CONSTANTS.PENDING) {
                                                    setPageNumber(1);
                                                    setPagination(
                                                        defaultPagination
                                                    );
                                                    setTab(CONSTANTS.PENDING);
                                                    setIsActive(true);
                                                }
                                            }}
                                        >
                                            Pending
                                        </button>
                                    </li>
                                    <li
                                        className="nav-item tab-filled-item"
                                        role="presentation"
                                    >
                                        <button
                                            name="tab_2"
                                            onKeyDown={(keyEvent) => {
                                                onkeyDownCallback(
                                                    keyEvent,
                                                    "tab_",
                                                    4
                                                );
                                            }}
                                            className={`nav-link ${
                                                tab === CONSTANTS.REGISTRED
                                                    ? "active"
                                                    : ""
                                            }`}
                                            id="registered-tab"
                                            data-toggle="tab"
                                            data-target="#registered-facilities-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="registered-facilities-tab"
                                            aria-selected="false"
                                            onClick={() => {
                                                if (
                                                    tab !== CONSTANTS.REGISTRED
                                                ) {
                                                    setPageNumber(1);
                                                    setPagination(
                                                        defaultPagination
                                                    );
                                                    setTab(CONSTANTS.REGISTRED);
                                                    setIsActive(true);
                                                }
                                            }}
                                        >
                                            Registered
                                        </button>
                                    </li>
                                    <li
                                        className="nav-item tab-filled-item"
                                        role="presentation"
                                    >
                                        <button
                                            name="tab_3"
                                            onKeyDown={(keyDownEvent) => {
                                                onkeyDownCallback(
                                                    keyDownEvent,
                                                    "tab_",
                                                    4
                                                );
                                            }}
                                            className={`nav-link ${
                                                tab === CONSTANTS.CERTIFIED
                                                    ? "active"
                                                    : ""
                                            }`}
                                            id="certified-tab"
                                            data-toggle="tab"
                                            data-target="#certified-facilities-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="certified-facilities-tab"
                                            aria-selected="false"
                                            onClick={() => {
                                                if (
                                                    tab !== CONSTANTS.CERTIFIED
                                                ) {
                                                    setPageNumber(1);
                                                    setPagination(
                                                        defaultPagination
                                                    );
                                                    setTab(CONSTANTS.CERTIFIED);
                                                    setIsActive(true);
                                                }
                                            }}
                                        >
                                            Awarded
                                        </button>
                                    </li>
                                    <li
                                        className="nav-item tab-filled-item"
                                        role="presentation"
                                    >
                                        <button
                                            name="tab_4"
                                            onKeyDown={(
                                                onkeyDownCallbackEvent
                                            ) => {
                                                onkeyDownCallback(
                                                    onkeyDownCallbackEvent,
                                                    "tab_",
                                                    4
                                                );
                                            }}
                                            className={`nav-link ${
                                                tab === CONSTANTS.DEACTIVATED
                                                    ? "active"
                                                    : ""
                                            }`}
                                            id="deactivated-tab"
                                            data-toggle="tab"
                                            data-target="#deactivated-facilities-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="deactivated-facilities-tab"
                                            aria-selected="false"
                                            onClick={() => {
                                                if (
                                                    tab !==
                                                    CONSTANTS.DEACTIVATED
                                                ) {
                                                    setPageNumber(1);
                                                    setPagination(
                                                        defaultPagination
                                                    );
                                                    setTab(
                                                        CONSTANTS.DEACTIVATED
                                                    );
                                                    setIsActive(false);
                                                }
                                            }}
                                        >
                                            Deactivated
                                        </button>
                                    </li>
                                </ul>
                                <div
                                    className="tab-content admin-main-tabs"
                                    id="myTabContent"
                                >
                                    {tab === CONSTANTS.PENDING && (
                                        <div
                                            className={`tab-pane fade ${
                                                tab === CONSTANTS.PENDING
                                                    ? "show active"
                                                    : ""
                                            }`}
                                            id="pending-approval-tab"
                                            role="tabpanel"
                                            aria-labelledby="pending-tab"
                                            tabIndex={0}
                                        >
                                            <div className="row">
                                                <div className="col-md-12 col-xl-12">
                                                    <SearchFilter
                                                        placeholder="By Organization Name/AHA EMS ID/Code"
                                                        programCategories={
                                                            awardCategories
                                                        }
                                                        setDefaultPagination={
                                                            setDefaultPagination
                                                        }
                                                        stateCd={stateCd}
                                                        setStateCd={setStateCd}
                                                        awardCd={awardCd}
                                                        setAwardCd={setAwardCd}
                                                        measureCd={measureCd}
                                                        setMeasureCd={
                                                            setMeasureCd
                                                        }
                                                        tabName={
                                                            CONSTANTS.PENDING
                                                        }
                                                        setClearFilters={
                                                            setClearFilters
                                                        }
                                                        setSortInd={setSortInd}
                                                        countryDropdown={
                                                            countryDropdown
                                                        }
                                                        stateDropdown={
                                                            stateDropdown
                                                        }
                                                        isCountryLoading={
                                                            isCountryLoading
                                                        }
                                                        isStateLoading={
                                                            isStateLoading
                                                        }
                                                        getFacilitiesResult={
                                                            getFacilitiesResult
                                                        }
                                                        isPending
                                                        emsInd
                                                        tab={tab}
                                                        setFilterInd={
                                                            setFilterInd
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 d-md-flex justify-content-between align-items-end">
                                                <TableRowCount
                                                    isFirst={
                                                        pendingPagination.isFirst
                                                    }
                                                    pageNumber={
                                                        pendingPagination.pageNumber
                                                    }
                                                    pageSize={
                                                        pendingPagination.pageSize
                                                    }
                                                    isLast={
                                                        pendingPagination.isLast
                                                    }
                                                    totalCount={
                                                        pendingPagination.totalCount
                                                    }
                                                    roleValue={roleValue}
                                                />
                                                <div className="mt-3 mt-md-0">
                                                    <SortTableColumnDropdown
                                                        getHospitalsForAdminManage={
                                                            getHospitalsForAdminManage
                                                        }
                                                        setPendingHospitals={
                                                            setPendingHospitals
                                                        }
                                                        status={tab}
                                                        measureCd={measureCd}
                                                        awardCd={awardCd}
                                                        pageNumber={pageNumber}
                                                        pageSize={
                                                            pendingPagination.pageSize
                                                        }
                                                        isActive={isActive}
                                                        categoryId={
                                                            CONSTANTS.EMS_PROGRAM_CATEGORY_ID
                                                        }
                                                        searchKey={
                                                            searchFilter.searchKey
                                                        }
                                                        sortIndValue={
                                                            sortIndValue
                                                        }
                                                        sortByLabel=""
                                                        id="pendingSort"
                                                        stateCd={stateCd}
                                                        country={country}
                                                        emsInd
                                                    />
                                                </div>
                                            </div>
                                            {isTableLoading ? (
                                                <div
                                                    role="alert"
                                                    aria-label="pending approval table is loading"
                                                    className="sr-only"
                                                />
                                            ) : (
                                                ""
                                            )}
                                            <EMSAgencyTable
                                                tab={tab}
                                                pageNumber={pageNumber}
                                                hospitals={pendingHospitals}
                                                actionURL="/emsadmin/organization-management/approve-organization/"
                                                isTableLoading={isTableLoading}
                                                isActive={isActive}
                                                isDeactivated={false}
                                                close={close}
                                                setSortInd={setSortInd}
                                                sortInd={sortInd}
                                                isPending
                                                stateCd={stateCd}
                                                awardCd={awardCd}
                                                measureCd={measureCd}
                                            />

                                            <div>
                                                {pendingPagination &&
                                                Number(
                                                    pendingPagination.totalPages
                                                ) > 0 ? (
                                                    <Pagination
                                                        pageNumber={
                                                            pendingPagination.pageNumber
                                                        }
                                                        pageSize={
                                                            pendingPagination.pageSize
                                                        }
                                                        totalCount={
                                                            pendingPagination.totalCount
                                                        }
                                                        totalPages={
                                                            pendingPagination.totalPages
                                                        }
                                                        isFirst={
                                                            pendingPagination.isFirst
                                                        }
                                                        isLast={
                                                            pendingPagination.isLast
                                                        }
                                                        setPageNumber={
                                                            pendingPagination.setPageNumber
                                                        }
                                                        paginationSetLimit={
                                                            paginationSetLimit
                                                        }
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                    )}
                                    {tab === CONSTANTS.REGISTRED && (
                                        <div
                                            className={`tab-pane fade ${
                                                tab === CONSTANTS.REGISTRED
                                                    ? "show active"
                                                    : ""
                                            }`}
                                            id="registered-facilities-tab"
                                            role="tabpanel"
                                            tabIndex={0}
                                            aria-labelledby="registered-tab"
                                        >
                                            <div className="row">
                                                <div className="col-md-12 col-xl-12">
                                                    <SearchFilter
                                                        placeholder="By Organization Name/AHA EMS ID/Code"
                                                        setDefaultPagination={
                                                            setDefaultPagination
                                                        }
                                                        programCategories={
                                                            awardCategories
                                                        }
                                                        stateCd={stateCd}
                                                        setStateCd={setStateCd}
                                                        awardCd={awardCd}
                                                        setAwardCd={setAwardCd}
                                                        measureCd={measureCd}
                                                        setMeasureCd={
                                                            setMeasureCd
                                                        }
                                                        tabName={
                                                            CONSTANTS.REGISTRED
                                                        }
                                                        setClearFilters={
                                                            setClearFilters
                                                        }
                                                        setSortInd={setSortInd}
                                                        stateDropdown={
                                                            stateDropdown
                                                        }
                                                        isStateLoading={
                                                            isStateLoading
                                                        }
                                                        getFacilitiesResult={
                                                            getFacilitiesResult
                                                        }
                                                        emsInd
                                                        setFilterInd={
                                                            setFilterInd
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 d-md-flex justify-content-between align-items-end">
                                                <TableRowCount
                                                    isFirst={
                                                        registeredPagination.isFirst
                                                    }
                                                    pageNumber={
                                                        registeredPagination.pageNumber
                                                    }
                                                    pageSize={
                                                        registeredPagination.pageSize
                                                    }
                                                    isLast={
                                                        registeredPagination.isLast
                                                    }
                                                    totalCount={
                                                        registeredPagination.totalCount
                                                    }
                                                    roleValue={roleValue}
                                                />
                                                <div className="mt-3 mt-md-0">
                                                    <SortTableColumnDropdown
                                                        getHospitalsForAdminManage={
                                                            getHospitalsForAdminManage
                                                        }
                                                        setRegisteredHospitals={
                                                            setRegisteredHospitals
                                                        }
                                                        status={tab}
                                                        pageNumber={pageNumber}
                                                        measureCd={measureCd}
                                                        awardCd={awardCd}
                                                        pageSize={
                                                            registeredPagination.pageSize
                                                        }
                                                        isActive={isActive}
                                                        categoryId={
                                                            CONSTANTS.EMS_PROGRAM_CATEGORY_ID
                                                        }
                                                        searchKey={
                                                            searchFilter.searchKey
                                                        }
                                                        sortIndValue={
                                                            sortIndValue
                                                        }
                                                        id="registeredSort"
                                                        stateCd={stateCd}
                                                        country={country}
                                                        emsInd
                                                    />
                                                </div>
                                            </div>
                                            {isTableLoading ? (
                                                <div
                                                    role="alert"
                                                    aria-label="registered organizations table is loading"
                                                    className="sr-only"
                                                />
                                            ) : (
                                                ""
                                            )}
                                            <EMSAgencyTable
                                                tab={tab}
                                                pageNumber={pageNumber}
                                                hospitals={registeredHospitals}
                                                actionURL="/emsadmin/organization-management/organization-details/"
                                                isTableLoading={isTableLoading}
                                                isActive={isActive}
                                                isDeactivated={false}
                                                close={close}
                                                setSortInd={setSortInd}
                                                sortInd={sortInd}
                                                stateCd={stateCd}
                                            />
                                            <div>
                                                {registeredPagination &&
                                                Number(
                                                    registeredPagination.totalPages
                                                ) > 0 ? (
                                                    <Pagination
                                                        pageNumber={
                                                            registeredPagination.pageNumber
                                                        }
                                                        pageSize={
                                                            registeredPagination.pageSize
                                                        }
                                                        totalCount={
                                                            registeredPagination.totalCount
                                                        }
                                                        totalPages={
                                                            registeredPagination.totalPages
                                                        }
                                                        isFirst={
                                                            registeredPagination.isFirst
                                                        }
                                                        isLast={
                                                            registeredPagination.isLast
                                                        }
                                                        setPageNumber={
                                                            registeredPagination.setPageNumber
                                                        }
                                                        paginationSetLimit={
                                                            paginationSetLimit
                                                        }
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                    )}
                                    {tab === CONSTANTS.CERTIFIED && (
                                        <div
                                            className={`tab-pane fade ${
                                                tab === CONSTANTS.CERTIFIED
                                                    ? "show active"
                                                    : ""
                                            }`}
                                            id="certified-facilities-tab"
                                            role="tabpanel"
                                            tabIndex={0}
                                            aria-labelledby="certified-tab"
                                        >
                                            <div className="row">
                                                <div className="col-md-12 col-xl-12">
                                                    <SearchFilter
                                                        placeholder="By Organization Name/AHA EMS ID/Code"
                                                        setDefaultPagination={
                                                            setDefaultPagination
                                                        }
                                                        programCategories={
                                                            awardCategories
                                                        }
                                                        stateCd={stateCd}
                                                        setStateCd={setStateCd}
                                                        awardCd={awardCd}
                                                        setAwardCd={setAwardCd}
                                                        measureCd={measureCd}
                                                        setMeasureCd={
                                                            setMeasureCd
                                                        }
                                                        tabName={
                                                            CONSTANTS.CERTIFIED
                                                        }
                                                        setClearFilters={
                                                            setClearFilters
                                                        }
                                                        setSortInd={setSortInd}
                                                        stateDropdown={
                                                            stateDropdown
                                                        }
                                                        isStateLoading={
                                                            isStateLoading
                                                        }
                                                        getFacilitiesResult={
                                                            getFacilitiesResult
                                                        }
                                                        emsInd
                                                        setFilterInd={
                                                            setFilterInd
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 d-md-flex justify-content-between align-items-end">
                                                <TableRowCount
                                                    isFirst={
                                                        awardedPagination.isFirst
                                                    }
                                                    pageNumber={
                                                        awardedPagination.pageNumber
                                                    }
                                                    pageSize={
                                                        awardedPagination.pageSize
                                                    }
                                                    isLast={
                                                        awardedPagination.isLast
                                                    }
                                                    totalCount={
                                                        awardedPagination.totalCount
                                                    }
                                                    roleValue={roleValue}
                                                />
                                                <div className="mt-3 mt-md-0">
                                                    <SortTableColumnDropdown
                                                        getHospitalsForAdminManage={
                                                            getHospitalsForAdminManage
                                                        }
                                                        setCertifiedHospitals={
                                                            setCertifiedHospitals
                                                        }
                                                        status={tab}
                                                        pageNumber={pageNumber}
                                                        measureCd={measureCd}
                                                        awardCd={awardCd}
                                                        pageSize={
                                                            awardedPagination.pageSize
                                                        }
                                                        isActive={isActive}
                                                        categoryId={
                                                            CONSTANTS.EMS_PROGRAM_CATEGORY_ID
                                                        }
                                                        searchKey={
                                                            searchFilter.searchKey
                                                        }
                                                        sortIndValue={
                                                            sortIndValue
                                                        }
                                                        id="certifiedSort"
                                                        stateCd={stateCd}
                                                        country={country}
                                                        emsInd
                                                    />
                                                </div>
                                            </div>
                                            {isTableLoading ? (
                                                <div
                                                    role="alert"
                                                    aria-label="registered organizations table is loading"
                                                    className="sr-only"
                                                />
                                            ) : (
                                                ""
                                            )}
                                            <EMSAgencyTable
                                                tab={tab}
                                                pageNumber={pageNumber}
                                                hospitals={certifiedHospitals}
                                                actionURL="/emsadmin/organization-management/organization-details/"
                                                isTableLoading={isTableLoading}
                                                isActive={isActive}
                                                isDeactivated={false}
                                                close={close}
                                                setSortInd={setSortInd}
                                                sortInd={sortInd}
                                                stateCd={stateCd}
                                            />
                                            <div>
                                                {awardedPagination &&
                                                Number(
                                                    awardedPagination.totalPages
                                                ) > 0 ? (
                                                    <Pagination
                                                        pageNumber={
                                                            awardedPagination.pageNumber
                                                        }
                                                        pageSize={
                                                            awardedPagination.pageSize
                                                        }
                                                        totalCount={
                                                            awardedPagination.totalCount
                                                        }
                                                        totalPages={
                                                            awardedPagination.totalPages
                                                        }
                                                        isFirst={
                                                            awardedPagination.isFirst
                                                        }
                                                        isLast={
                                                            awardedPagination.isLast
                                                        }
                                                        setPageNumber={
                                                            awardedPagination.setPageNumber
                                                        }
                                                        paginationSetLimit={
                                                            paginationSetLimit
                                                        }
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                    )}
                                    {tab === CONSTANTS.DEACTIVATED && (
                                        <div
                                            className={`tab-pane fade ${
                                                tab === CONSTANTS.DEACTIVATED
                                                    ? "show active"
                                                    : ""
                                            }`}
                                            id="deactivated-facilities-tab"
                                            role="tabpanel"
                                            tabIndex={0}
                                            aria-labelledby="deactivated-tab"
                                        >
                                            <div className="row">
                                                <div className="col-md-12 col-xl-12">
                                                    <SearchFilter
                                                        placeholder="By Organization Name/AHA EMS ID/Code"
                                                        programCategories={
                                                            awardCategories
                                                        }
                                                        setDefaultPagination={
                                                            setDefaultPagination
                                                        }
                                                        stateCd={stateCd}
                                                        setStateCd={setStateCd}
                                                        awardCd={awardCd}
                                                        setAwardCd={setAwardCd}
                                                        measureCd={measureCd}
                                                        setMeasureCd={
                                                            setMeasureCd
                                                        }
                                                        tabName={
                                                            CONSTANTS.DEACTIVATED
                                                        }
                                                        setClearFilters={
                                                            setClearFilters
                                                        }
                                                        setSortInd={setSortInd}
                                                        countryDropdown={
                                                            countryDropdown
                                                        }
                                                        stateDropdown={
                                                            stateDropdown
                                                        }
                                                        isCountryLoading={
                                                            isCountryLoading
                                                        }
                                                        isStateLoading={
                                                            isStateLoading
                                                        }
                                                        getFacilitiesResult={
                                                            getFacilitiesResult
                                                        }
                                                        emsInd
                                                        setFilterInd={
                                                            setFilterInd
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 d-md-flex justify-content-between align-items-end">
                                                <TableRowCount
                                                    isFirst={
                                                        deactivatedPagination.isFirst
                                                    }
                                                    pageNumber={
                                                        deactivatedPagination.pageNumber
                                                    }
                                                    pageSize={
                                                        deactivatedPagination.pageSize
                                                    }
                                                    isLast={
                                                        deactivatedPagination.isLast
                                                    }
                                                    totalCount={
                                                        deactivatedPagination.totalCount
                                                    }
                                                    roleValue={roleValue}
                                                />
                                                <div className="mt-3 mt-md-0">
                                                    <SortTableColumnDropdown
                                                        getHospitalsForAdminManage={
                                                            getHospitalsForAdminManage
                                                        }
                                                        setDeactivatedHospitals={
                                                            setDeactivatedHospitals
                                                        }
                                                        status={tab}
                                                        pageNumber={pageNumber}
                                                        measureCd={measureCd}
                                                        awardCd={awardCd}
                                                        pageSize={
                                                            deactivatedPagination.pageSize
                                                        }
                                                        isActive={isActive}
                                                        categoryId={
                                                            CONSTANTS.EMS_PROGRAM_CATEGORY_ID
                                                        }
                                                        searchKey={
                                                            searchFilter.searchKey
                                                        }
                                                        sortIndValue={
                                                            sortIndValue
                                                        }
                                                        id="deactivatedSort"
                                                        stateCd={stateCd}
                                                        country={country}
                                                        emsInd
                                                    />
                                                </div>
                                            </div>
                                            {isTableLoading ? (
                                                <div
                                                    role="alert"
                                                    aria-label="deactivated organizations table is loading"
                                                    className="sr-only"
                                                />
                                            ) : (
                                                ""
                                            )}
                                            <EMSAgencyTable
                                                tab={tab}
                                                pageNumber={pageNumber}
                                                hospitals={deactivatedHospitals}
                                                actionURL="/emsadmin/organization-management/approve-organization/"
                                                isTableLoading={isTableLoading}
                                                isActive={isActive}
                                                isDeactivated
                                                close={close}
                                                setSortInd={setSortInd}
                                                sortInd={sortInd}
                                                stateCd={stateCd}
                                            />
                                            <div>
                                                {deactivatedPagination &&
                                                Number(
                                                    deactivatedPagination.totalPages
                                                ) > 0 ? (
                                                    <Pagination
                                                        pageNumber={
                                                            deactivatedPagination.pageNumber
                                                        }
                                                        pageSize={
                                                            deactivatedPagination.pageSize
                                                        }
                                                        totalCount={
                                                            deactivatedPagination.totalCount
                                                        }
                                                        totalPages={
                                                            deactivatedPagination.totalPages
                                                        }
                                                        isFirst={
                                                            deactivatedPagination.isFirst
                                                        }
                                                        isLast={
                                                            deactivatedPagination.isLast
                                                        }
                                                        setPageNumber={
                                                            deactivatedPagination.setPageNumber
                                                        }
                                                        paginationSetLimit={
                                                            paginationSetLimit
                                                        }
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {bukRenewModalActiveInd && (
                <FocusTrap
                    focusTrapOptions={{
                        escapeDeactivates: false,
                        clickOutsideDeactivates: false,
                    }}
                >
                    <div
                        className="modal fade show aui-modal "
                        id="bulkRenewModal"
                        tabIndex={-1}
                        aria-labelledby="bulkRenewModalLabel"
                        aria-modal="true"
                        role="dialog"
                        style={{
                            display: bukRenewModalActiveInd ? "block" : "none",
                        }}
                    >
                        <div className="modal-dialog modal-dialog-centered modal-sm">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="d-flex align-items-center">
                                        <h5
                                            className="h5 mb-0"
                                            id="selctedFacilityModalLabel"
                                        >
                                            {bulkRenewModalHeader}
                                        </h5>
                                    </div>
                                    <button
                                        id="closeselectedFacilitiesModal"
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close selected organizations modal"
                                        onClick={dismissBulkRenewModal}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className="aha-icon-cross"
                                        />
                                    </button>
                                </div>
                                <div className="modal-body p-0">
                                    <div>
                                        <p>{bulkRenewPopupMsg}</p>
                                        {facilityRegCount === 0 &&
                                        !bulkRenewFailPopUp &&
                                        measureLockInd ? (
                                            <div className="mt-2 d-flex justify-content-end">
                                                <button
                                                    className="btn btn-secondary btn-round btn-sm mx-2"
                                                    data-dismiss="modal"
                                                    aria-label="Cancel Bulk renew  and close the modal"
                                                    onClick={
                                                        dismissBulkRenewModal
                                                    }
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    className="btn btn-primary btn-round btn-sm mx-2"
                                                    data-dismiss="modal"
                                                    aria-label="Confirm bulk renew"
                                                    onClick={confirmBulkRenew}
                                                >
                                                    Confirm
                                                </button>
                                            </div>
                                        ) : (
                                            <div className="mt-2 d-flex justify-content-end">
                                                <button
                                                    className="btn btn-primary btn-round btn-sm mx-2 px-5"
                                                    data-dismiss="modal"
                                                    aria-label="OK and close the modal"
                                                    onClick={
                                                        dismissBulkRenewModal
                                                    }
                                                >
                                                    OK
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FocusTrap>
            )}
        </OrganizationManagementWrapper>
    );
};

export default OrganizationManagement;
