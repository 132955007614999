import store from "app/store";
import { validJSON } from "common/utils";
import { showToast } from "components/Toast/toast.slice";
import APIUtils from "utils/API.utils";
import CONSTANTS from "common/constants";
import config from "../config";

const API = new APIUtils();

declare global {
    interface Window {
        userAccessToken: any;
    }
}

export const catchManager = (error: any, reject: any) => {
    const err: any = error;
    store.dispatch(showToast(err));
    reject(error);
};

export const approveHospital = async (
    hospitalId: number,
    programId: number,
    categoryId: number,
    status: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = {
                hospitalId: Number(hospitalId),
                programId: Number(programId),
                categoryId: Number(categoryId),
                approvalStatus: status,
            };
            const APIRes: any = await API.PATCH(
                `${config[config.env].apiEndpoints.accounts}/admin/verify`,
                reqBody
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            reject(error);
        }
    });
};

export const getDashboardDetails = async (region: string): Promise<any> => {
    if (!localStorage.getItem("userAccessToken")) {
        return [];
    }
    const myHeaders: any = new Headers();
    myHeaders.append(
        "authorization",
        localStorage.getItem("userAccessToken") || ""
    );
    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };

    const endpoint =
        region != CONSTANTS.ALL
            ? `${
                  config[config.env].apiEndpoints.accounts
              }/hospitals/adminDashboardSummary?region=${region}`
            : `${
                  config[config.env].apiEndpoints.accounts
              }/hospitals/adminDashboardSummary`;

    return new Promise((resolve, reject) => {
        fetch(endpoint, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                if (validJSON(result)) {
                    const adminDashboardResponse = JSON.parse(result);
                    if (adminDashboardResponse.success === true) {
                        resolve(adminDashboardResponse);
                    } else {
                        throw adminDashboardResponse.error;
                    }
                } else {
                    throw new Error("Invalid json string in the response");
                }
            })
            .catch((error) => {
                return catchManager(error, reject);
            });
    });
};

export const getNewlyCreatedHospitalPrograms = async (
    region: string,
    isActive: boolean = true
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const endpoint =
                region != CONSTANTS.ALL
                    ? `${
                          config[config.env].apiEndpoints.accounts
                      }/hospitalPrograms?region=${region}&isActive=${isActive}&certifiedFacility=false&orderBy=approved_date,DESC&pageSize=5&pageNumber=1&approvalStatus=APPROVED`
                    : `${
                          config[config.env].apiEndpoints.accounts
                      }/hospitalPrograms?isActive=${isActive}&certifiedFacility=false&orderBy=approved_date,DESC&pageSize=5&pageNumber=1&approvalStatus=APPROVED`;

            const APIRes = await API.GET(endpoint);
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getHospitalsForAdminManage = async (
    requestObject: any
): Promise<any> => {
    const {
        status,
        pageNumber,
        region,
        pageSize,
        isActive,
        categoryId,
        country,
        sortInd,
        stateCd,
        awardCd,
        measureCd,
        searchKey = "",
        sortOrganizations,
    } = requestObject;
    return new Promise(async (resolve, reject) => {
        try {
            let apiEndpoint = `${
                config[config.env].apiEndpoints.accounts
            }/hospitalPrograms?approvalStatus=${status}&isActive=${isActive}&pageSize=${pageSize}&pageNumber=${pageNumber}&certifiedFacility=false&searchKeyword=${encodeURIComponent(
                searchKey
            )}`;
            switch (status) {
                case "DEACTIVATED":
                    if (
                        [CONSTANTS.DOMESTIC, CONSTANTS.INTERNATIONAL].indexOf(
                            region
                        ) >= 0
                    ) {
                        apiEndpoint = `${
                            config[config.env].apiEndpoints.accounts
                        }/hospitalPrograms?region=${region}&isActive=${isActive}&pageSize=${pageSize}&pageNumber=${pageNumber}&searchKeyword=${encodeURIComponent(
                            searchKey
                        )}`;
                    } else if (region == CONSTANTS.ALL) {
                        apiEndpoint = `${
                            config[config.env].apiEndpoints.accounts
                        }/hospitalPrograms?isActive=${isActive}&pageSize=${pageSize}&pageNumber=${pageNumber}&searchKeyword=${encodeURIComponent(
                            searchKey
                        )}`;
                    } else if (sortOrganizations === CONSTANTS.ALL) {
                        apiEndpoint = `${
                            config[config.env].apiEndpoints.accounts
                        }/hospitalPrograms?isActive=${isActive}&pageSize=${pageSize}&pageNumber=${pageNumber}&searchKeyword=${encodeURIComponent(
                            searchKey
                        )}`;
                    }
                    break;
                case "CERTIFIED":
                    if (
                        [CONSTANTS.DOMESTIC, CONSTANTS.INTERNATIONAL].indexOf(
                            region
                        ) >= 0
                    ) {
                        apiEndpoint = `${
                            config[config.env].apiEndpoints.accounts
                        }/hospitalPrograms?region=${region}&isActive=${isActive}&pageSize=${pageSize}&pageNumber=${pageNumber}&certifiedFacility=true&searchKeyword=${encodeURIComponent(
                            searchKey
                        )}`;
                    } else if (region == CONSTANTS.ALL) {
                        apiEndpoint = `${
                            config[config.env].apiEndpoints.accounts
                        }/hospitalPrograms?isActive=${isActive}&pageSize=${pageSize}&pageNumber=${pageNumber}&certifiedFacility=true&searchKeyword=${encodeURIComponent(
                            searchKey
                        )}`;
                    } else if (
                        sortOrganizations === CONSTANTS.ALL ||
                        [
                            CONSTANTS.EXPIRY_ASCENDING,
                            CONSTANTS.EXPIRY_DESCENDING,
                            CONSTANTS.DEFAULT,
                        ].indexOf(sortOrganizations) >= 0
                    ) {
                        apiEndpoint = `${
                            config[config.env].apiEndpoints.accounts
                        }/hospitalPrograms?isActive=${isActive}&pageSize=${pageSize}&pageNumber=${pageNumber}&certifiedFacility=true&searchKeyword=${encodeURIComponent(
                            searchKey
                        )}`;
                    }
                    break;
                case CONSTANTS.REGISTRED:
                    if (
                        [CONSTANTS.DOMESTIC, CONSTANTS.INTERNATIONAL].indexOf(
                            region
                        ) >= 0
                    ) {
                        apiEndpoint = `${
                            config[config.env].apiEndpoints.accounts
                        }/hospitalPrograms?region=${region}&approvalStatus=${status}&isActive=${isActive}&pageSize=${pageSize}&pageNumber=${pageNumber}&certifiedFacility=false&searchKeyword=${encodeURIComponent(
                            searchKey
                        )}`;
                    } else if (region == CONSTANTS.ALL) {
                        apiEndpoint = `${
                            config[config.env].apiEndpoints.accounts
                        }/hospitalPrograms?approvalStatus=${status}&isActive=${isActive}&pageSize=${pageSize}&pageNumber=${pageNumber}&certifiedFacility=false&searchKeyword=${encodeURIComponent(
                            searchKey
                        )}`;
                    }
                    break;
                case CONSTANTS.RENEWING:
                    if (
                        [CONSTANTS.DOMESTIC, CONSTANTS.INTERNATIONAL].indexOf(
                            region
                        ) >= 0
                    ) {
                        apiEndpoint = `${
                            config[config.env].apiEndpoints.accounts
                        }/hospitalPrograms?region=${region}&approvalStatus=${status}&isActive=${isActive}&pageSize=${pageSize}&pageNumber=${pageNumber}&certifiedFacility=false&searchKeyword=${encodeURIComponent(
                            searchKey
                        )}`;
                    } else if (region == CONSTANTS.ALL) {
                        apiEndpoint = `${
                            config[config.env].apiEndpoints.accounts
                        }/hospitalPrograms?approvalStatus=${status}&isActive=${isActive}&pageSize=${pageSize}&pageNumber=${pageNumber}&certifiedFacility=false&searchKeyword=${encodeURIComponent(
                            searchKey
                        )}`;
                    }
                    break;
                case CONSTANTS.PENDING:
                    if (
                        [CONSTANTS.DOMESTIC, CONSTANTS.INTERNATIONAL].indexOf(
                            region
                        ) >= 0
                    ) {
                        apiEndpoint = `${
                            config[config.env].apiEndpoints.accounts
                        }/hospitalPrograms?region=${region}&approvalStatus=${status}&isActive=${isActive}&pageSize=${pageSize}&pageNumber=${pageNumber}&certifiedFacility=false&searchKeyword=${encodeURIComponent(
                            searchKey
                        )}`;
                    }
                    break;
                default:
                    apiEndpoint = `${
                        config[config.env].apiEndpoints.accounts
                    }/hospitalPrograms?approvalStatus=${status}&isActive=${isActive}&orderBy=created_at,DESC&pageSize=${pageSize}&pageNumber=${pageNumber}&certifiedFacility=false&searchKeyword=${encodeURIComponent(
                        searchKey
                    )}`;
                    break;
            }
            if (categoryId !== CONSTANTS.ALL) {
                apiEndpoint = `${apiEndpoint}&categoryId=${categoryId}`;
            }
            if (
                country !== CONSTANTS.ALL &&
                region === CONSTANTS.INTERNATIONAL
            ) {
                apiEndpoint = `${apiEndpoint}&countryCode=${country}`;
            }
            if (
                stateCd &&
                stateCd !== CONSTANTS.ALL &&
                stateCd !== "All" &&
                region === CONSTANTS.DOMESTIC
            ) {
                apiEndpoint = `${apiEndpoint}&stateName=${stateCd}`;
            }
            if (
                awardCd &&
                awardCd !== CONSTANTS.ALL &&
                awardCd !== CONSTANTS.ALL_VALUE
            ) {
                apiEndpoint = `${apiEndpoint}&awardName=${encodeURIComponent(
                    awardCd
                )}`;
            }
            if (
                measureCd &&
                measureCd !== CONSTANTS.ALL &&
                measureCd !== CONSTANTS.ALL_VALUE
            ) {
                apiEndpoint = `${apiEndpoint}&measureIndicator=${measureCd}`;
            }
            switch (sortInd) {
                case true:
                    if (
                        status === CONSTANTS.CERTIFIED &&
                        sortOrganizations === CONSTANTS.EXPIRY_ASCENDING
                    ) {
                        apiEndpoint = `${apiEndpoint}&orderBy=certificateExpiryDate,ASC`;
                    } else {
                        apiEndpoint = `${apiEndpoint}&orderBy=facilityName,ASC`;
                    }
                    break;
                case false:
                    if (
                        status === CONSTANTS.CERTIFIED &&
                        sortOrganizations === CONSTANTS.EXPIRY_DESCENDING
                    ) {
                        apiEndpoint = `${apiEndpoint}&orderBy=certificateExpiryDate,DESC`;
                    } else {
                        apiEndpoint = `${apiEndpoint}&orderBy=facilityName,DESC`;
                    }
                    break;
                default:
                    if (status == CONSTANTS.DEACTIVATED) {
                        apiEndpoint = `${apiEndpoint}&orderBy=updated_at,DESC`;
                    }
                    if (
                        status == CONSTANTS.PENDING ||
                        status == CONSTANTS.CERTIFIED
                    ) {
                        apiEndpoint = `${apiEndpoint}&orderBy=created_at,DESC`;
                    }
                    if (status == CONSTANTS.RENEWING) {
                        apiEndpoint = `${apiEndpoint}&orderBy=last_archived_at,DESC`;
                    }
                    if (status == CONSTANTS.REGISTRED) {
                        apiEndpoint = `${apiEndpoint}&orderBy=approved_date,DESC`;
                    }
            }
            const APIRes = await API.GET(apiEndpoint);
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const updateFacilityActivationStatus = async (
    facilityStatus: boolean,
    facilityCode: string
): Promise<any> => {
    return new Promise((resolve, reject) => {
        API.PATCH(
            `${
                config[config.env].apiEndpoints.accounts
            }/admin/hospital/activationStatus`,
            { facilityStatus, facilityCode }
        )
            .then((response: any) => {
                if (response.success === true) {
                    resolve(response);
                } else {
                    const errors: any = response.error;
                    throw errors.response.data.error;
                }
            })
            .catch((errorObj) => {
                return catchManager(errorObj, reject);
            });
    });
};

export const deactivateHospital = async (
    facilityCode: any,
    facilityStatus: boolean,
    hospitalId?: any,
    approvalStatus?: any,
    programCategoryId?: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.PATCH(
                `${
                    config[config.env].apiEndpoints.accounts
                }/admin/hospital/activationStatus`,
                {
                    facilityStatus,
                    facilityCode,
                    hospitalId,
                    approvalStatus,
                    programCategoryId,
                }
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getSubscriptionDetails = async (
    hospitalId: number,
    programId: number,
    categoryId: number
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/admin/getSubscriptionDetails/hospitals/${hospitalId}/programs/${programId}/categories/${categoryId}`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getPaymentStatus = async (orderId: number): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/admin/hospitalProgram/paymentStatus/${orderId}`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const updatePaymentStatus = async (
    hospitalProgramDetails: any,
    paymentDetails: any,
    orderId: number
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = {
                hospitalProgramDetails,
                paymentDetails,
            };

            const APIRes = await API.PATCH(
                `${
                    config[config.env].apiEndpoints.accounts
                }/admin/hospitalProgram/paymentStatus/${orderId}`,
                reqBody
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getNewlyCertifiedFacility = async (
    region: string,
    isActive: boolean = true
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const endpoint =
                region != CONSTANTS.ALL
                    ? `${
                          config[config.env].apiEndpoints.accounts
                      }/hospitalPrograms?region=${region}&isActive=${isActive}&orderBy=created_at,DESC&pageSize=5&pageNumber=1&certifiedFacility=true`
                    : `${
                          config[config.env].apiEndpoints.accounts
                      }/hospitalPrograms?isActive=${isActive}&orderBy=created_at,DESC&pageSize=5&pageNumber=1&certifiedFacility=true`;

            const APIRes = await API.GET(endpoint);
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const renewFacility = async (
    hospitalId: any,
    programId: any,
    categoryId: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = {};
            const APIRes = await API.POST(
                `${
                    config[config.env].apiEndpoints.accounts
                }/admin/renewFacility/hospital/${hospitalId}/program/${programId}/category/${categoryId}`,
                reqBody
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};
