import store from "app/store";
import { paymentMadeBy } from "common/utils";
import { showToast } from "components/Toast/toast.slice";
import APIUtils from "utils/API.utils";
import axios, { AxiosProgressEvent } from "axios";
import CONSTANTS from "common/constants";
import config from "../config";
import { logger } from "../utils/logger.utils";

const API = new APIUtils();

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

declare global {
    interface Window {
        userAccessToken: any;
    }
}

export const getAgreementDownloadUrl = async (
    uploadId: number,
    hospitalId: number | string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.documents
                }/uploads/hospitals/${hospitalId}/files/${uploadId}`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const moveAgreementPdfToMainBucket = async (
    uploadId: number,
    hospitalId: number,
    programId: number,
    categoryId: number
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = {
                hospitalId,
                programId,
                categoryId,
                documentType: "AGREEMENT_UPLOAD",
            };

            const raw = JSON.stringify(reqBody);

            const APIRes = await API.PATCH(
                `${
                    config[config.env].apiEndpoints.documents
                }/v2/uploads/${uploadId}`,
                raw
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const saveAgreementDetails = async (
    agreementData: any,
    programId: any,
    hospitalId: any,
    isEUP?: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = agreementData;
            if (programId == CONSTANTS.EMS_PROGRAM_ID) {
                "finalAgreementId" in reqBody &&
                    delete reqBody.finalAgreementId;
                "participantTaxId" in reqBody &&
                    delete reqBody.participantTaxId;
                "agreementReferenceId" in reqBody &&
                    delete reqBody.agreementReferenceId;
                !isEUP && delete reqBody.facilityName;
            }

            const APIRes = await API.PATCH(
                `${
                    config[config.env].apiEndpoints.accounts
                }/hospitals/${hospitalId}/programs/${programId}/agreement`,
                reqBody
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            err.details[0] = await paymentMadeBy(
                err.code,
                err.details[0],
                isEUP
            );
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getAgreementDetails = async (
    programId: any,
    hospitalId: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/hospitals/${hospitalId}/programs/${programId}/agreement`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getPreSignedURL = async (
    hospitalId: any,
    programId: any,
    categoryId: any,
    file: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = {
                hospitalId,
                programId,
                categoryId,
                documentType: "AGREEMENT_UPLOAD",
                fileName: file,
                region: timeZone,
            };

            const APIRes = await API.POST(
                `${config[config.env].apiEndpoints.documents}/v2/uploads`,
                reqBody
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getCountryProgramAgreement = async (
    programId: number,
    categoryId: number,
    countryId: number
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.catalogs
                }/programs/${programId}/categories/${categoryId}/countries/${countryId}/agreement:template`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const uploadPdfToS3 = async (
    hospitalId: any,
    programId: any,
    categoryId: any,
    file: any,
    updateUploadProgress: any
) => {
    const presignedResponse: any = await getPreSignedURL(
        hospitalId,
        programId,
        categoryId,
        file.name
    );

    if (presignedResponse.success === true) {
        const axiosResponse = await axios({
            method: "put",
            url: presignedResponse.data.presignedUrl,
            data: file,
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            headers: {
                "Content-Type": "application/pdf",
            },
            onUploadProgress: (ev: AxiosProgressEvent) => {
                const progress = (ev.loaded / (ev?.total || Infinity)) * 100;
                updateUploadProgress(
                    Math.round(progress) === 100 ? 99 : Math.round(progress)
                );
            },
        })
            .then(async (response: any) => {
                if (response.status == 200) {
                    moveAgreementPdfToMainBucket(
                        presignedResponse.data.uploadId,
                        hospitalId,
                        programId,
                        categoryId
                    );
                    updateUploadProgress(100);
                }
                return {
                    uploadId: presignedResponse.data.uploadId,
                    fileName: file.name,
                };
            })
            .catch((error: any) => {
                logger("File upload-Error:", error);
                return error;
            });
        return axiosResponse;
    }
    return null;
};

export const deleteUploadedDoc = async (uploadId: number): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.DELETE(
                `${
                    config[config.env].apiEndpoints.documents
                }/uploads/${uploadId}`
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};
