import Dropdown from "components/Dropdown";
import SmartSearch from "components/SmartSearch";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchDropdown from "components/SearchDropdown";
import { getAwardCategories } from "api/certificateAPI";
import { logger } from "utils/logger.utils";
import CONSTANTS from "../../common/constants";
import SelectDomesticInternational from "../SelectDomesticInternational";
import { SearchFilterWrapper } from "./styled";
import {
    setProgramCategoryId,
    setSearchKey,
    setCountryCode,
    setStateCode,
    setSortOrganizations,
    setAward,
    setMeasure,
    setLogsStateCode,
    setLogsSearchKey,
} from "./searchFilter.slice";

interface Props {
    placeholder: string;
    awardCategories?: any;
    programCategories?: any;
    programCategory?: any;
    setProgramCategory?: any;
    setDefaultPagination: any;
    country?: any;
    setCountry?: any;
    stateCd?: any;
    setStateCd?: any;
    awardCd?: any;
    setAwardCd?: any;
    measureCd?: any;
    setMeasureCd?: any;
    tabName?: string;
    setClearFilters: any;
    countryDropdown?: any;
    stateDropdown?: any;
    isCountryLoading?: boolean;
    isStateLoading?: boolean;
    getFacilitiesResult: any;
    emsInd?: boolean;
    setSortInd?: any;
    awardCategory?: any;
    emsAward?: any;
    isAwardLoading?: any;
    setAwardCategory?: any;
    clearFiltersInd?: any;
    setState?: any;
    returnPath?: any;
    isPending?: boolean;
    tab?: string;
    setFilterInd?: any;
}

const SearchFilter = ({
    awardCategories,
    placeholder,
    programCategories,
    awardCategory,
    programCategory,
    setProgramCategory,
    setDefaultPagination,
    country,
    setCountry,
    stateCd,
    setStateCd,
    awardCd,
    setAwardCd,
    measureCd,
    setMeasureCd,
    tabName,
    setClearFilters,
    countryDropdown,
    stateDropdown,
    isCountryLoading,
    isStateLoading,
    setAwardCategory,
    getFacilitiesResult,
    emsInd,
    setSortInd,
    emsAward,
    setState,
    returnPath,
    isPending,
    setFilterInd,
    tab,
}: Props) => {
    const dispatch = useDispatch();
    const [programsDisabled, setProgramsDisabled] = useState<boolean>(false);
    const region = useSelector((state: any) => {
        return state.region;
    });
    const searchFilter = useSelector((state: any) => {
        return state.searchFilter;
    });
    const [clearFilterInd, setClearFilterInd] = useState<boolean>(false);
    const [mounted, setMounted] = useState<boolean>(false);
    const [searchTimer, setSearchTimer] = useState<any>();
    const [measureDropdown, setMeasureDropdown] = useState<any>([
        { label: "All", value: CONSTANTS.ALL },
        { label: "Measure Submitted", value: "Measure Submitted" },
        { label: "Measure Saved", value: "Measure Saved" },
        { label: "Measure No Data", value: "Measure No Data" },
    ]);
    const [isAwardLoading, setIsAwardLoading] = useState<boolean>(false);
    const [isMeasureLoading, setIsMeasureLoading] = useState<boolean>(false);

    useEffect(() => {
        if (mounted) {
            setCountry(CONSTANTS.ALL);
            setStateCd(CONSTANTS.ALL);
            dispatch(setCountryCode(CONSTANTS.ALL));
            dispatch(setStateCode(CONSTANTS.ALL));
        }
        setMounted(true);
    }, [region.region]);

    useEffect(() => {
        if (programCategories?.length > 0) {
            setProgramsDisabled(false);
        } else {
            setProgramsDisabled(true);
        }
    }, [programCategories]);

    const changeSearchKey = (value: any) => {
        if (setFilterInd) setFilterInd(true);
        setDefaultPagination();
        dispatch(setSearchKey(value));
    };

    const changeProgramCategory = (value: any, clearFilter?: boolean) => {
        if (!clearFilter) {
            getFacilitiesResult({ categoryId: value });
        }
        setProgramCategory(value);
        if (searchFilter.programCategory !== value) {
            setDefaultPagination();
            dispatch(setProgramCategoryId(value));
        }
    };

    const changeCountry = (value: any, clearFilter?: boolean) => {
        if (!clearFilter) {
            getFacilitiesResult({ countryCd: value });
        }
        setCountry(value);
        if (searchFilter.countryCode !== value) {
            setDefaultPagination();
            dispatch(setCountryCode(value));
        }
    };

    const changeState = (value: any, clearFilter?: boolean) => {
        if (!clearFilter) {
            if (setFilterInd) setFilterInd(true);
            getFacilitiesResult({ stateCode: value });
        }
        setStateCd(value);
        if (searchFilter.stateCode !== value) {
            setDefaultPagination();
            dispatch(setStateCode(value));
        }
    };

    const changeAward = (value: any, clearFilter?: boolean) => {
        if (!clearFilter) {
            if (setFilterInd) setFilterInd(true);
            getFacilitiesResult({ award: value, pageInd: true });
        }
        if (setAwardCd) setAwardCd(value);
        if (setAwardCategory) setAwardCategory(value);
        if (searchFilter.award !== value) {
            setDefaultPagination();
            dispatch(setAward(value));
        }
    };

    const changeMeasure = (value: any, clearFilter?: boolean) => {
        if (!clearFilter) {
            if (setFilterInd) setFilterInd(true);
            getFacilitiesResult({ measures: value });
        }
        if (emsInd) {
            setMeasureCd(value);
        }
        if (searchFilter.measure !== value) {
            setDefaultPagination();
            dispatch(setMeasure(value));
        }
    };

    useEffect(() => {
        if (emsAward) clearAllFilters();
    }, [returnPath]);

    const changeLogsState = (value: any, clearFilter?: boolean) => {
        if (!clearFilter) {
            if (setFilterInd) setFilterInd(true);
            getFacilitiesResult({ state: value, pageInd: true });
        }
        setState(value);
        if (searchFilter.logsStateCode !== value) {
            setDefaultPagination();
            dispatch(setLogsStateCode(value));
        }
    };
    const changeLogsSearchKey = (value: any) => {
        if (setFilterInd) setFilterInd(true);
        setDefaultPagination();
        dispatch(setLogsSearchKey(value));
    };

    const clearAllFilters = () => {
        setClearFilters(true);
        if (
            (programCategory && programCategory !== CONSTANTS.ALL) ||
            (programCategory && programCategory !== CONSTANTS.ALL_VALUE)
        ) {
            if (emsAward) {
                changeAward(CONSTANTS.ALL_VALUE, true);
            } else {
                changeProgramCategory(CONSTANTS.ALL, true);
            }
        }
        if (emsInd || region.region === CONSTANTS.DOMESTIC || emsAward) {
            if (stateCd !== CONSTANTS.ALL) {
                emsAward
                    ? changeLogsState(CONSTANTS.ALL, true)
                    : changeState(CONSTANTS.ALL, true);
            }
            if (awardCd !== CONSTANTS.ALL && !emsAward) {
                changeAward(CONSTANTS.ALL, true);
            }
            if (measureCd !== CONSTANTS.ALL) {
                changeMeasure(CONSTANTS.ALL, true);
            }
        }
        if (
            region.region === CONSTANTS.INTERNATIONAL &&
            country !== CONSTANTS.ALL
        ) {
            changeCountry(CONSTANTS.ALL, true);
        }
        if (region.region !== CONSTANTS.ALL) {
            setClearFilterInd(!clearFilterInd);
        }
        if (searchFilter.sortOrganizations !== CONSTANTS.ALL) {
            setClearFilterInd(!clearFilterInd);
            setSortInd(undefined);
            dispatch(setSortOrganizations(CONSTANTS.ALL));
        }
        if (searchFilter.searchKey) {
            clearTimeout(searchTimer);
            changeSearchKey("");
        }
        if (searchFilter.logsSearchKey) {
            clearTimeout(searchTimer);
            changeLogsSearchKey("");
        }
        emsAward
            ? getFacilitiesResult({
                  award: CONSTANTS.ALL_VALUE,
                  measures: CONSTANTS.ALL_VALUE,
                  state: CONSTANTS.ALL,
                  order: CONSTANTS.DESC,
                  orderBy: CONSTANTS.DEFAULT_ORDER_BY,
                  search: "",
                  pageInd: true,
              })
            : getFacilitiesResult({
                  regionName: CONSTANTS.ALL,
                  categoryId: CONSTANTS.ALL,
                  countryCd: CONSTANTS.ALL,
                  sortIndVal: null,
                  stateCode: CONSTANTS.ALL,
                  search: "",
                  award: CONSTANTS.ALL_VALUE,
                  measures: CONSTANTS.ALL_VALUE,
              });
    };

    const getSearchedItemsTable = (value: any) => {
        getFacilitiesResult({ search: value, pageInd: true });
    };

    return (
        <SearchFilterWrapper
            className="search-filter-block"
            role="group"
            aria-label="filters"
        >
            <div className="search-filter-field">
                <div className="search-filter search-filter-item">
                    <label htmlFor={`searchFilter-${tabName}`}>Search</label>
                    <SmartSearch
                        searchKey={
                            emsAward
                                ? searchFilter.logsSearchKey
                                : searchFilter.searchKey
                        }
                        getSearchedItems={getSearchedItemsTable}
                        arialabel=""
                        id={`searchFilter-${tabName}`}
                        placeholder={placeholder}
                        setSearchTimer={setSearchTimer}
                        {...(emsAward
                            ? { setLogsSearchKey: changeLogsSearchKey }
                            : { setSearchKey: changeSearchKey })}
                    />
                </div>
                {!emsInd && !emsAward && (
                    <div className="location-filter search-filter-item">
                        <label
                            htmlFor={`locationFilter-${tabName}`}
                            id={`locationFilter-${tabName}-label`}
                        >
                            Region
                        </label>
                        <SelectDomesticInternational
                            clearFilterInd={clearFilterInd}
                            setDefaultPagination={setDefaultPagination}
                            id={`locationFilter-${tabName}`}
                            getFacilitiesResult={getFacilitiesResult}
                        />
                    </div>
                )}
                {(region.region === CONSTANTS.DOMESTIC || emsInd) && (
                    <div className="state-filter search-filter-item">
                        <label
                            htmlFor={`stateFilter-${tabName}`}
                            id={`stateFilter-${tabName}-label`}
                        >
                            State
                        </label>
                        <SearchDropdown
                            id={`stateFilter-${tabName}`}
                            items={stateDropdown}
                            selectedValue={stateCd}
                            callParentOnSelect={(value: any) =>
                                changeState(value)
                            }
                            disabled={isStateLoading}
                            isLoading={isStateLoading}
                            placeholder="Select"
                            label="State"
                        />
                    </div>
                )}
                {emsInd && (
                    <>
                        <div className="award-filter search-filter-item">
                            <label
                                htmlFor={`awardTypeFilter-${tabName}`}
                                id={`awardTypeFilter-${tabName}-label`}
                            >
                                Award
                            </label>
                            <SearchDropdown
                                id={`awardTypeFilter-${tabName}`}
                                items={programCategories}
                                selectedValue={awardCd}
                                callParentOnSelect={(value: any) =>
                                    changeAward(value)
                                }
                                disabled={isAwardLoading || isPending}
                                isLoading={isAwardLoading}
                                placeholder="Select Award"
                                label="Award"
                                tab={tab}
                            />
                        </div>
                        <div className="measure-filter search-filter-item">
                            <label
                                htmlFor={`measureFilter-${tabName}`}
                                id={`measureFilter-${tabName}-label`}
                            >
                                Measure
                            </label>
                            <SearchDropdown
                                id={`measureFilter-${tabName}`}
                                items={measureDropdown}
                                selectedValue={measureCd}
                                callParentOnSelect={(value: any) =>
                                    changeMeasure(value)
                                }
                                disabled={isMeasureLoading || isPending}
                                isLoading={isMeasureLoading}
                                placeholder="Select Measure"
                                label="Measure"
                                tab={tab}
                            />
                        </div>
                    </>
                )}
                {region.region === CONSTANTS.INTERNATIONAL && (
                    <div className="state-filter search-filter-item">
                        <label
                            htmlFor={`countryFilter-${tabName}`}
                            id={`countryFilter-${tabName}-label`}
                        >
                            Location
                        </label>
                        <SearchDropdown
                            id={`countryFilter-${tabName}`}
                            items={countryDropdown.filter(
                                (countryItem: any) =>
                                    countryItem.label !=
                                    CONSTANTS.DOMESTIC_COUNTRY_NAME
                            )}
                            selectedValue={country}
                            callParentOnSelect={(value: any) =>
                                changeCountry(value)
                            }
                            disabled={isCountryLoading}
                            isLoading={isCountryLoading}
                            placeholder="Select"
                            label="Location"
                        />
                    </div>
                )}
                {region.region === CONSTANTS.ALL && !emsInd && !emsAward && (
                    <div className="state-filter search-filter-item">
                        <label
                            htmlFor={`stateOrCountryFilter-${tabName}`}
                            id={`stateOrCountryFilter-${tabName}-label`}
                        >
                            State/Location
                        </label>
                        <Dropdown
                            id={`stateOrCountryFilter-${tabName}`}
                            items={[{ label: "All", value: CONSTANTS.ALL }]}
                            selectedValue={CONSTANTS.ALL}
                            callParentOnSelect={{}}
                        />
                    </div>
                )}
                {!emsInd && !emsAward && (
                    <div className="program-filter search-filter-item">
                        <label
                            htmlFor={`programTypeFilter-${tabName}`}
                            id={`programTypeFilter-${tabName}-label`}
                        >
                            Program Type
                        </label>
                        <SearchDropdown
                            id={`programTypeFilter-${tabName}`}
                            items={programCategories}
                            selectedValue={programCategory}
                            callParentOnSelect={(value: any) =>
                                changeProgramCategory(value)
                            }
                            isLoading={programsDisabled}
                            disabled={programsDisabled}
                            placeholder="Select"
                            label="Program Type"
                        />
                    </div>
                )}
                {emsAward && (
                    <div className="state-filter search-filter-item">
                        <label
                            htmlFor={`stateFilter-${tabName}`}
                            id={`awardTypeFilter-${tabName}-label`}
                        >
                            State
                        </label>
                        <SearchDropdown
                            id={`awardTypeFilter-${tabName}`}
                            items={stateDropdown}
                            selectedValue={stateCd}
                            callParentOnSelect={(value: any) =>
                                changeLogsState(value)
                            }
                            isLoading={isStateLoading}
                            disabled={isStateLoading}
                            placeholder="State"
                            label="State"
                        />
                    </div>
                )}
                {emsAward && (
                    <div className="award-filter search-filter-item">
                        <label
                            htmlFor={`awardTypeFilter-${tabName}`}
                            id={`awardTypeFilter-${tabName}-label`}
                        >
                            Award
                        </label>
                        <SearchDropdown
                            id={`awardTypeFilter-${tabName}`}
                            items={programCategories}
                            selectedValue={programCategory}
                            callParentOnSelect={(value: any) =>
                                changeAward(value)
                            }
                            isLoading={isStateLoading}
                            disabled={isStateLoading}
                            placeholder="Select Award"
                            label="Award"
                        />
                    </div>
                )}
            </div>
            {(searchFilter.searchKey ||
                searchFilter.logsSearchKey ||
                (region.region && region.region !== CONSTANTS.ALL) ||
                (searchFilter.sortOrganizations &&
                    searchFilter.sortOrganizations !== CONSTANTS.ALL) ||
                (country && country !== CONSTANTS.ALL) ||
                (stateCd &&
                    stateCd !== CONSTANTS.ALL &&
                    stateCd !== CONSTANTS.ALL_VALUE) ||
                (awardCd &&
                    awardCd !== CONSTANTS.ALL &&
                    awardCd !== CONSTANTS.ALL_VALUE) ||
                (measureCd &&
                    measureCd !== CONSTANTS.ALL &&
                    measureCd !== CONSTANTS.ALL_VALUE) ||
                (!emsAward &&
                    !emsInd &&
                    searchFilter.programCategory &&
                    searchFilter.programCategory !== CONSTANTS.ALL) ||
                (emsAward &&
                    programCategory &&
                    programCategory.toLowerCase() !==
                        CONSTANTS.ALL.toLowerCase())) &&
                (!emsInd || (emsInd && tabName !== CONSTANTS.PENDING)) && (
                    <div className="d-flex flex-row-reverse mt-n1 mt-sm-n2 mt-xl-2 mb-n3">
                        <button
                            className="btn-text-link-uline font-14"
                            onClick={clearAllFilters}
                        >
                            <i className="aha-icon-close mr-1" />
                            Clear Filter
                        </button>
                    </div>
                )}
        </SearchFilterWrapper>
    );
};

export default SearchFilter;
