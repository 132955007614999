import store from "app/store";
import { validJSON } from "common/utils";
import { showToast } from "components/Toast/toast.slice";
import axios, { AxiosProgressEvent } from "axios";
import config from "../config";
import { logger } from "../utils/logger.utils";

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

export default class DocumentServiceAPI {
    catchManager = (error: any, reject: any) => {
        const err: any = error;
        store.dispatch(showToast(err));
        reject(error);
    };

    get = async (
        hospitalId: number,
        programId: number,
        categoryId: number,
        standardId: number,
        approvalStatus: string,
        sortByDate: string,
        pageNumber: number
    ) => {
        if (!localStorage.getItem("userAccessToken")) {
            return false;
        }
        const myHeaders = new Headers();
        myHeaders.append(
            "authorization",
            localStorage.getItem("userAccessToken") || ""
        );
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
        };
        let url = `${
            config[config.env].apiEndpoints.accounts
        }/hospitalProgramUploads/hospitals/${hospitalId}?documentType=DOCUMENT_UPLOAD&pageNumber=${pageNumber}&pageSize=${10}&hospitalId=${hospitalId}&programId=${programId}&categoryId=${categoryId}&orderBy=createdAt,${sortByDate}&datestamp=${Date.now()}`;
        if (standardId && standardId != -1) {
            url += `&standardId=${standardId}`;
        }
        if (approvalStatus && approvalStatus != "All") {
            url += `&approvalStatus=${approvalStatus}`;
        }

        return new Promise((resolve, reject) => {
            fetch(url, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    if (validJSON(result)) {
                        const uploadedDocuments = JSON.parse(result);
                        if (uploadedDocuments.success === true) {
                            resolve(uploadedDocuments);
                        } else {
                            throw uploadedDocuments.error;
                        }
                    } else {
                        throw new Error("Invalid json string in the response");
                    }
                })
                .catch((error) => {
                    return this.catchManager(error, reject);
                });
        });
    };

    getByReferenceId = async (referenceId: string, hospitalId: number) => {
        if (!localStorage.getItem("userAccessToken")) {
            return false;
        }
        const myHeaders = new Headers();
        myHeaders.append(
            "authorization",
            localStorage.getItem("userAccessToken") || ""
        );
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        const url = `${
            config[config.env].apiEndpoints.accounts
        }/hospitalProgramUploads/hospitals/${hospitalId}/reference/${referenceId}`;

        return new Promise((resolve, reject) => {
            fetch(url, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    if (validJSON(result)) {
                        const uploadedDocument = JSON.parse(result);
                        if (uploadedDocument.success === true) {
                            resolve(uploadedDocument);
                        } else {
                            throw uploadedDocument.error;
                        }
                    } else {
                        throw new Error("Invalid json string in the response");
                    }
                })
                .catch((error) => {
                    return this.catchManager(error, reject);
                });
        });
    };

    delete = async (
        id: number,
        hospitalId: any,
        individualDocInd: boolean
    ): Promise<any> => {
        if (!localStorage.getItem("userAccessToken")) {
            return [];
        }
        const myHeaders = new Headers();
        myHeaders.append(
            "authorization",
            localStorage.getItem("userAccessToken") || ""
        );
        const requestOptions = {
            method: "DELETE",
            headers: myHeaders,
        };

        const apiEndPoint = individualDocInd
            ? `${
                  config[config.env].apiEndpoints.accounts
              }/hospitalProgramUploads/hospitals/${hospitalId}/document/${id}`
            : `${
                  config[config.env].apiEndpoints.accounts
              }/hospitalProgramUploads/hospitals/${hospitalId}/reference/${id}`;

        return new Promise((resolve, reject) => {
            fetch(apiEndPoint, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    if (validJSON(result)) {
                        const deleteDocumentResponse = JSON.parse(result);
                        if (deleteDocumentResponse.success === true) {
                            resolve(deleteDocumentResponse);
                        } else {
                            throw deleteDocumentResponse.error;
                        }
                    } else {
                        throw new Error("Invalid json string in the response");
                    }
                })
                .catch((error) => {
                    return this.catchManager(error, reject);
                });
        });
    };

    upload = async (
        hospitalId: number,
        programId: number,
        categoryId: number,
        file: any,
        setUploadId: any,
        updateUploadProgress: any
    ) => {
        const presignedResponse: any = await this.getPreSignedURL(
            hospitalId,
            programId,
            categoryId,
            file.name
        );
        if (presignedResponse.success === true) {
            const axiosResponse = await axios({
                method: "put",
                url: presignedResponse.data.presignedUrl,
                data: file,
                maxContentLength: Infinity,
                maxBodyLength: Infinity,
                headers: {
                    "Content-Type": "application/octet-stream",
                },
                onUploadProgress: (ev: AxiosProgressEvent) => {
                    const progress =
                        (ev.loaded / (ev?.total || Infinity)) * 100;
                    updateUploadProgress(
                        Math.round(progress) === 100 ? 99 : Math.round(progress)
                    );
                },
            })
                .then((response: any) => {
                    if (response.status == 200) {
                        setUploadId(presignedResponse.data.uploadId);
                        updateUploadProgress(100);
                    }
                    return response;
                })
                .catch((error: any) => {
                    logger("File upload-Error:", error);
                    setUploadId(undefined);
                    return error;
                });
            return axiosResponse;
        }
        return null;
    };

    submitUploadedDocument = async (
        uploadDocFrom: any,
        selectedProgram: any,
        uploadId: number,
        hospitalId: any
    ) => {
        if (!localStorage.getItem("userAccessToken")) {
            return false;
        }
        const myHeaders = new Headers();
        myHeaders.append(
            "authorization",
            localStorage.getItem("userAccessToken") || ""
        );
        myHeaders.append("Content-Type", "application/json");
        const postBody = {
            programId: selectedProgram.programId,
            categoryId: selectedProgram.programCategoryId,
            documentId: uploadId,
            fileName: uploadDocFrom.file.name,
            fileType: uploadDocFrom.file.type,
            documentType: "DOCUMENT_UPLOAD",
            programName: selectedProgram.name,
            standardId: uploadDocFrom.uploadStandard?.split("###")[0],
            standardName: uploadDocFrom.uploadStandard?.split("###")[1],
            documentTitle: uploadDocFrom.title,
            documentDescription: uploadDocFrom.uploadDescription,
            facilityMessage: uploadDocFrom.facilityMessage,
            status: "INITIATED",
            referenceId: uploadDocFrom.referenceId,
            hospitalId: hospitalId || undefined,
            isBulkUpload: hospitalId.length > 1,
        };

        const raw = JSON.stringify(postBody);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
        };

        return new Promise((resolve, reject) => {
            fetch(
                `${
                    config[config.env].apiEndpoints.accounts
                }/hospitalProgramUploads`,
                requestOptions
            )
                .then((response) => response.text())
                .then((result) => {
                    if (validJSON(result)) {
                        const submitUploadedDocument = JSON.parse(result);
                        if (
                            submitUploadedDocument.statusCode == 200 ||
                            submitUploadedDocument.statusCode == 201
                        ) {
                            resolve(submitUploadedDocument);
                        } else {
                            throw submitUploadedDocument.error;
                        }
                    } else {
                        throw new Error("Invalid json string in the response");
                    }
                })
                .catch((error) => {
                    return this.catchManager(error, reject);
                });
        });
    };

    moveFileFromS3TempToMainBucket = async (
        uploadId: number,
        hospitalId: number,
        programId: number,
        categoryId: number
    ) => {
        if (!localStorage.getItem("userAccessToken")) {
            return false;
        }
        const myHeaders = new Headers();
        myHeaders.append(
            "authorization",
            localStorage.getItem("userAccessToken") || ""
        );
        myHeaders.append("Content-Type", "application/json");

        const postBody = {
            hospitalId,
            programId,
            categoryId,
            documentType: "DOCUMENT_UPLOAD",
        };

        const raw = JSON.stringify(postBody);

        const requestOptions = {
            method: "PATCH",
            headers: myHeaders,
            body: raw,
        };

        return new Promise((resolve, reject) => {
            fetch(
                `${
                    config[config.env].apiEndpoints.documents
                }/v2/uploads/${uploadId}`,
                requestOptions
            )
                .then((response) => response.text())
                .then((result) => {
                    if (validJSON(result)) {
                        const csvSubmitResponse = JSON.parse(result);
                        if (
                            csvSubmitResponse.statusCode == 200 ||
                            csvSubmitResponse.statusCode == 201
                        ) {
                            resolve(csvSubmitResponse);
                        } else {
                            throw csvSubmitResponse.error;
                        }
                    } else {
                        throw new Error("Invalid json string in the response");
                    }
                })
                .catch((error) => {
                    return this.catchManager(error, reject);
                });
        });
    };

    getStandardsByProgramId = async (categoryId: number): Promise<any> => {
        if (!localStorage.getItem("userAccessToken")) {
            return [];
        }
        const myHeaders = new Headers();
        myHeaders.append(
            "authorization",
            localStorage.getItem("userAccessToken") || ""
        );
        const requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        return new Promise((resolve, reject) => {
            fetch(
                `${
                    config[config.env].apiEndpoints.catalogs
                }/standards?categoryId=${categoryId}&orderBy=standardSequence,ASC`,
                requestOptions
            )
                .then((response) => response.text())
                .then((res) => {
                    if (validJSON(res)) {
                        const standardsRes = JSON.parse(res);
                        if (standardsRes.success === true) {
                            resolve(standardsRes);
                        } else {
                            throw standardsRes.error;
                        }
                    } else {
                        throw new Error("Invalid json string in the response");
                    }
                })
                .catch((error) => {
                    return this.catchManager(error, reject);
                });
        });
    };

    getStandardsByStandardId = async (standardId: number): Promise<any> => {
        if (!localStorage.getItem("userAccessToken")) {
            return [];
        }
        const myHeaders = new Headers();
        myHeaders.append(
            "authorization",
            localStorage.getItem("userAccessToken") || ""
        );
        const requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        return new Promise((resolve, reject) => {
            fetch(
                `${
                    config[config.env].apiEndpoints.catalogs
                }/standards/${standardId}`,
                requestOptions
            )
                .then((response) => response.text())
                .then((result) => {
                    if (validJSON(result)) {
                        const standardsResponse = JSON.parse(result);
                        if (standardsResponse.success === true) {
                            resolve(standardsResponse);
                        } else {
                            throw standardsResponse.error;
                        }
                    } else {
                        throw new Error("Invalid json string in the response");
                    }
                })
                .catch((error) => {
                    return this.catchManager(error, reject);
                });
        });
    };

    getPreSignedURL = async (
        hospitalId: number,
        programId: number,
        categoryId: number,
        fileName: string
    ) => {
        if (!localStorage.getItem("userAccessToken")) {
            return false;
        }
        const myHeaders = new Headers();
        myHeaders.append(
            "authorization",
            localStorage.getItem("userAccessToken") || ""
        );
        myHeaders.append("Content-Type", "application/json");
        const postBody = {
            hospitalId,
            programId,
            categoryId,
            documentType: "DOCUMENT_UPLOAD",
            fileName,
            region: timeZone,
        };

        const raw = JSON.stringify(postBody);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
        };

        return new Promise((resolve, reject) => {
            fetch(
                `${config[config.env].apiEndpoints.documents}/v2/uploads`,
                requestOptions
            )
                .then((response) => response.text())
                .then((result) => {
                    if (validJSON(result)) {
                        const preSignedURL = JSON.parse(result);
                        if (preSignedURL.success === true) {
                            resolve(preSignedURL);
                        } else {
                            throw preSignedURL.error;
                        }
                    } else {
                        throw new Error("Invalid json string in the response");
                    }
                })
                .catch((error) => {
                    return this.catchManager(error, reject);
                });
        });
    };

    download = async (documentId: number | string, hospitalId: any = null) => {
        const presignedResponse: any = await this.getDownloadPresignedURL(
            documentId,
            hospitalId
        );
        if (presignedResponse.success === true) {
            window.location.assign(presignedResponse.data.uploads.fileUrl);
        }
    };

    getDownloadPresignedURL = async (
        documentId: number | string,
        hospitalId: number
    ): Promise<any> => {
        if (!localStorage.getItem("userAccessToken")) {
            return [];
        }
        const myHeaders: any = new Headers();
        myHeaders.append(
            "authorization",
            localStorage.getItem("userAccessToken") || ""
        );
        const requestOptions = {
            method: "GET",
            headers: myHeaders,
        };
        return new Promise((resolve, reject) => {
            fetch(
                `${
                    config[config.env].apiEndpoints.documents
                }/uploads/hospitals/${hospitalId}/files/${documentId}`,
                requestOptions
            )
                .then((response) => response.text())
                .then((result) => {
                    if (validJSON(result)) {
                        const preSignedURLResponse = JSON.parse(result);
                        if (preSignedURLResponse.statusCode == 200) {
                            resolve(preSignedURLResponse);
                        } else {
                            throw preSignedURLResponse.error;
                        }
                    } else {
                        throw new Error("Invalid json string in the response");
                    }
                })
                .catch((error) => {
                    return this.catchManager(error, reject);
                });
        });
    };

    approve = async (
        hospitalId: number,
        programId: number,
        categoryId: number,
        documentId: number,
        approvalStatus: string,
        reviewComment: string
    ) => {
        if (!localStorage.getItem("userAccessToken")) {
            return false;
        }
        const myHeaders = new Headers();
        myHeaders.append(
            "authorization",
            localStorage.getItem("userAccessToken") || ""
        );
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            hospitalId,
            programId,
            approvalStatus,
            documentId,
            categoryId,
            reviewComment: reviewComment.trim(),
        });

        const requestOptions = {
            method: "PATCH",
            headers: myHeaders,
            body: raw,
        };

        return new Promise((resolve, reject) => {
            fetch(
                `${
                    config[config.env].apiEndpoints.accounts
                }/admin/verify/document`,
                requestOptions
            )
                .then((response) => response.text())
                .then((result) => {
                    if (validJSON(result)) {
                        const approveResponse = JSON.parse(result);
                        logger("approveResponse:", approveResponse);
                        if (
                            approveResponse.statusCode == 200 ||
                            approveResponse.statusCode == 201
                        ) {
                            resolve(approveResponse);
                        } else {
                            throw approveResponse.error;
                        }
                    } else {
                        throw new Error("Invalid json string in the response");
                    }
                })
                .catch((error) => {
                    return this.catchManager(error, reject);
                });
        });
    };

    getDocumentCount = async (
        hospitalId: number,
        programId: number,
        categoryId: number
    ): Promise<any> => {
        if (!localStorage.getItem("userAccessToken")) {
            return [];
        }
        const myHeaders = new Headers();
        myHeaders.append(
            "authorization",
            localStorage.getItem("userAccessToken") || ""
        );
        const requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        return new Promise((resolve, reject) => {
            fetch(
                `${
                    config[config.env].apiEndpoints.accounts
                }/hospitalProgramUploads/documentCount/hospitals/${hospitalId}?documentType=DOCUMENT_UPLOAD&programId=${programId}&categoryId=${categoryId}`,
                requestOptions
            )
                .then((response) => response.text())
                .then((res) => {
                    if (validJSON(res)) {
                        const standardsRes = JSON.parse(res);
                        if (standardsRes.success === true) {
                            resolve(standardsRes);
                        } else {
                            throw standardsRes.error;
                        }
                    } else {
                        throw new Error("Invalid json string in the response");
                    }
                })
                .catch((error) => {
                    return this.catchManager(error, reject);
                });
        });
    };
}
