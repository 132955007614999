import styled from "styled-components";

export const AdminHeaderWrapper = styled.header`
    &.aui-pri-header {
        .aui-header-content {
            width: 100%;
        }
        .aui-pri-nav {
            .navbar-nav {
                margin: 0 0 0 16px;
            }
        }
        .aui-pri-header-t {
            padding: 10px 20px;
            @media only screen and (min-width: 768px) {
                padding: 15px 30px 15px;
            }
            @media only screen and (min-width: 1361px) {
                padding: 15px 30px 15px;
            }
        }
    }
    .qct-logo {
        padding-right: 2px;
        img {
            width: 160px;
            @media only screen and (min-width: 768px) {
                width: 230px;
            }
            @media only screen and (min-width: 992px) {
                width: 260px;
            }
        }
    }
    .navbar-toggler {
        color: #222328;
    }
`;
